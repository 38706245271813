<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: Please set LastEditors
 * @Date: 2019-07-09 16:55:50
 * @LastEditTime: 2024-02-27 10:29:26
 -->
<template>
  <div class="bm-sub-content">
    <!-- <div v-if="!isTiled" class="head">
      {{ column.name }}
    </div> -->
    <en-table
      border
      class="table"
      :data="column.subDetails"
      :show-header="isTiled"
      :span-method="arraySpanMethod"
      :row-style="tableRowStyle"
      header-cell-class-name="headRowStyle"
      :cell-class-name="cellClassFormat"
      :head-end-config="isTiled && column.editable ? {type:'add', rowDataMethod: headButtonClick} : {}"
      :hover-row-config="column.editable ? { data: lineBtnData, changeMethod: handleButtonClick1 } : {}"
    >
      <en-table-column  width="50" type="index">
      </en-table-column>
      <!-- 列表式 -->
      <template v-if="isTiled">
        <en-table-column min-width="280" v-for="(row, lineIndex) in column.sub" :key="lineIndex">
          <template slot="header">
            <span>{{ row.name }}</span>
            <span class="head-requred" v-if="row.requrein === 0">*</span>
            <span v-if="row.suffix">{{ row.suffix === "1" ? "(元)" : row.suffix === "2" ? "(万元)" : "(other??)" }}</span>
          </template>
          <template slot-scope="scope">
            <div class="wrapper">
              <business-column-rendering
                :hide-label="true"
                :column="scope.row.fields[lineIndex]"
                :business-data="businessData"
                 :checkProp="checkProp + '.subDetails.' + (scope.rowIndex) + '.fields.' + lineIndex"
              />
            </div>
          </template>
        </en-table-column>
      </template>
      <!-- 平铺式 -->
      <en-table-column min-width="500" v-if="!isTiled">
        <template slot-scope="scope">
          <div class="wrapper-tile">
            <business-column-rendering
              v-for="(lineColumn) in scope.row.fields"
              :key="lineColumn.uuid"
              :column="lineColumn"
              :business-data="businessData"
            />
          </div>
        </template>
      </en-table-column>
    </en-table>
  </div>
</template>

<script>
import MxColumnData from "../MxColumnData.js";
import { SubsetColumn } from "../../../data/columns/index";

export default {
  name: "BMSubsetComponent",
  mixins: [MxColumnData],
  props: {
    column: {
      type: SubsetColumn,
      require: true,
      default: {}
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    checkProp: {
      type: String,
      default: "column"
    },
    isTiled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    overflow() {
      return this.column.maxNum && this.column.subDetails.length >= this.column.maxNum;
    },
    lineBtnData() {
      let btnList = [...this.buttonData];
      // 平铺按钮组移除新增按钮
      if (this.isTiled && !this.overflow) {
        btnList.shift();
        // 超出最大值 只保留删除
      } else if (this.overflow) {
        btnList = [btnList[btnList.length - 1]];
      }
      return btnList;
    }
  },
  data() {
    return {
      headBtnData: [
        {
          name: "新增",
          icon: "tianjia-liebiao",
          iconColor: "#4895DF",
          fn: this.addOneLine
        }
      ],
      buttonData: [
        {
          name: "新增",
          icon: "tianjia-liebiao",
          iconColor: "#4895DF",
          fn: this.addOneLine
        },
        {
          name: "复制",
          icon: "fuzhi-kuaijiecaozuo",
          iconColor: "#58CB7E",
          fn: this.copyOneLine
        },
        {
          name: "删除",
          icon: "shibai",
          iconColor: "#F76B6B",
          fn: this.deleteOneLine
        }
      ]
    };
  },
  methods: {
    // 行处理
    tableRowStyle({ rowIndex }) {
      const styles = {};
      // 最后一行无border-bottom  目前无效
      if (rowIndex === this.column.subDetails?.length - 1) {
        styles.borderBottom = "none";
      }
      // 偶数行 带背景颜色
      if (rowIndex % 2 !== 0) {
        styles.backgroundColor = "#F5F8FC";
      }
      return styles;
    },
    // 单元格class
    cellClassFormat({ columnIndex }) {
      let cls = "";
      // 平铺模式
      if (!this.isTiled && columnIndex === 1) {
        cls = "no-pad-rt";
        // 行末尾合并单元格  button组单独设置无padding-right
      } else if (columnIndex === this.column.sub?.length) {
        cls = "no-pad-rt";
        // 行最后一个空td 无右侧边框
      } else if (columnIndex === this.column.sub?.length + 1) {
        cls = "last-td";
      }
      return cls;
    },
    // 倒数第二 合并 倒数第一单元格
    arraySpanMethod({ columnIndex }) {
      if (columnIndex === this.column.sub?.length - 1) {
        return [1, 2];
      }
      if (columnIndex === this.column.sub?.length) {
        return [0, 0];
      }
      return [1, 1];
    },
    // 列表式 头部 add
    headButtonClick() {
      const btnData = this.headBtnData[0];
      const { fn } = btnData;
      typeof fn === "function" && fn();
    },
    // 行button组 点击处理
    buttonClick(index, icon, lineIndex) {
      const btnData = this.lineBtnData[index];
      const { fn } = btnData;
      typeof fn === "function" && fn(lineIndex);
    },
    handleButtonClick1({ rowIndex, btnData }) {
      const { fn } = btnData;
      typeof fn === "function" && fn(rowIndex);
    },
    // 新增一行
    addOneLine(index) {
      if (Number(this.column.maxNum) && this.column.subDetails?.length >= this.column.maxNum) {
        this.$message(`该明细最大支持${this.column.maxNum}行`);
        return;
      }
      this.column.addOneLine(index);
    },
    deleteOneLine(index) {
      this.column.deleteOneLine(index);
    },
    // 复制一行
    copyOneLine(index) {
      if (Number(this.column.maxNum) && this.column.subDetails?.length >= this.column.maxNum) {
        this.$message(`该明细最大支持${this.column.maxNum}行`);
        return;
      }
      this.column.copyOneLine(index);
    }
  },
  created() {
    console.log(this.businessData, "checkProp");
  },
  beforeCreate() {
    this.$options.components.BusinessColumnRendering = () => import("../index");
  }
};
</script>
<style lang="scss" scoped>
// /deep/.row--hover{
//     background-color: #f0f9f6!important;
//   }
.bm-sub-content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ebeff4;
  padding: 10px;
  & /deep/ .vxe-table--header-wrapper,& /deep/ .vxe-header--row>.is--firstCol,& /deep/ .vxe-head-end-wrapper{
    background-color: #f0f9f6!important;
  }
  .head {
    background-color: #F0F9F6;
    height: 48px;
    color: #343434;
    font-size: 12px;
    font-weight: bold;
    line-height: 48px;
    padding-left: 20px;
  }
  .table,
  .head {
    width: 100%;
  }
  /deep/.vxe-header--row .is--firstCol {
    background-color: #F0F9F6;
  }
  /deep/.vxe-table .vxe-head-end-wrapper {
    background-color: #F0F9F6;
  }
  /deep/.vxe-table--header-wrapper {
    background-color: #F0F9F6;
  }
}

.headRowStyle {
  background-color: #F0F9F6;
}

</style>
