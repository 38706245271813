<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-09 18:18:50
 * @LastEditTime: 2020-06-18 15:29:55
 -->
<template>
  <div class="seperator-line">
    <div class="name">
      {{ column.name }}
    </div>
    <div
      class="line"
      :class="{'dashed':column.lineType === 2,'solid':!column.lineType || column.lineType === 1,'space':column.lineType === 3}"
    >
    </div>
  </div>
</template>
<script>
import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMSeperatorLineComponent",
  mixins: [MxColumnData],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>
<style scoped lang="scss">
.seperator-line {
  font-size: 12px;
  @include border-line;
  @include rounded-corners;
  height: 32px;
  box-sizing: border-box;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  .line {
    margin-left: 12px;
    flex: 1;
  }
  .dashed {
    height: 0;
    background-color: white;
    width: 200px;
    border: 1px dashed rgba(232, 236, 242, 1);
    float: right;
    margin-top: 14px;
  }
  .solid {
    height: 1px;
    width: 200px;
    background: rgba(232, 236, 242, 1);
    float: right;
    margin-top: 14px;
  }
  .space {
    height: 8px;
    background: rgba(232, 236, 242, 1);
    width: 200px;
    float: right;
    margin-top: 11px;
  }
}
</style>
