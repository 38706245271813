<!--
 * @Descripttion: 打印模板页
 * @Author: 彭博
 * @Date: 2020-08-20 16:58:02
 * @LastEditors: zhulin
 * @LastEditTime: 2021-10-19 10:44:17
-->
<template>
  <div class="print-html"  v-if="opened" >
    <!-- 标题 -->
    <div class="print-title">
      <span
        :style="{
          color: pageTitle.titleColor
        }"
        >{{ mdData.templateName }}</span
      >
      <div class="title-left">
        <img @load="sendLoadImg" v-if="pageTitle.printLogo === 0 && pageTitle.logoAlign === '1'" src="@/assets/images/enlogo.png" alt="" class="src" />
        <vue-barcode v-if="pageTitle.printCode === 0 && pageTitle.codeAlign === '1'" :value="mdData.code"  height="35" fontSize="16"></vue-barcode>
      </div>
      <div class="title-rt">
        <img @load="sendLoadImg" v-if="pageTitle.printLogo === 0 && pageTitle.logoAlign === '3'" src="@/assets/images/enlogo.png" alt="" class="src" />
        <vue-barcode v-if="pageTitle.printCode === 0 && pageTitle.codeAlign === '3'" :value="mdData.code"  height="35" fontSize="16"></vue-barcode>
      </div>
    </div>
    <div class="line-solid"></div>
    <div class="print-content">
      <div class="print-row" v-for="(row, rowIndex) in mdModel" :key="rowIndex" >
        <template>
          <div class="field-item" :class="{ 'no-flex': isNoFlex(field) }" v-for="(field, fieldIndex) in row.fields" :key="fieldIndex">
            <!-- feild 名称 -->
            <div
              class="label"
              :style="{
                fontWeight: field.printAttr.fontWeight && field.printAttr.fontWeight === '0'||showLabelField(field) ? 'bold' : '500',
                color: field.printAttr && field.printAttr.fontColor || getColor(field),
                fontSize: field.printAttr && field.printAttr.fontSize + 'px',
                display: (field.printAttr.showPrefix === '0' || field.printAttr.hide)&&!showLabelField(field) ? 'none' : ''
              }"
            >
              {{ field.name }}：
            </div>
            <!-- feild 内容 -->
            <div
              class="value"
              v-if="isDefault(field)"
              :style="getStyle(field)"
            >
              <div v-html="formatValues(field)"></div>
            </div>
            <!-- 分割线 -->
            <div class='line' v-else-if="isLine(field)">
                <hr style="border-style:dotted; height:1px" v-if="field.printAttr.lineType==1">
                <hr v-else-if="field.printAttr.lineType==2">
            </div>
            <!-- 标记完成 -->
            <div class='' v-else-if='isCheckBox(field)'>
              <input type="checkbox" :checked="!mdData[field.field]">{{targetFieldName(field)}}

            </div>
            <!-- 里程碑 -->
            <div class='line' v-else-if="isMileStone(field)">
                <!-- <hr style="border-style:dotted; height:1px" v-if="field.printAttr.lineType==1">
                <hr v-else-if="field.printAttr.lineType==2"> -->
            </div>
            <!-- 区域 areas -->
            <div class="area" v-else-if="isArea(field)">
              <div class="field-item" v-for="(area, areaIndex) in field.areas" :key="areaIndex">
                <div class="area-block">
                  <div class="field-item" v-for="(sub, subIndex) in area.sub" :key="subIndex">
                    <div class="label"
                      :style="{
                        fontWeight: sub.printAttr.fontWeight && sub.printAttr.fontWeight === '0' ? 'bold' : '500',
                        color: sub.printAttr && sub.printAttr.fontColor || getColor(sub.field),
                        fontSize: sub.printAttr && sub.printAttr.fontSize + 'px',
                        display: sub.printAttr.showPrefix === '1' ? 'none' : ''
                      }">{{ sub.name }}：</div>
                    <div class="value"
                      :style="getStyle(sub)">{{ formatValues(sub) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 图文合成 -->
            <div class='text-compose' v-else-if="isTextCompose(field)">
                <text-compose :column="findColumnByField(field.field)"
                  :business-data="businessData" @load='sendLoadImg()'>
                </text-compose>
            </div>
            <!-- 收付对象 -->
            <div class="detail-table" v-else-if="isCashObj(field)&&field.fillData&&field.fillData.length">
              <table border="1" cellspacing="0" cellpadding="8" >
                <tr>
                  <!-- 子集不显示 -->
                  <th :style="getStyle(field)" > 付款人名字</th>
                  <th :style="getStyle(field)" > 银行</th>
                  <th :style="getStyle(field)" > 账号</th>
                  <th :style="getStyle(field)" > 付款方式</th>
                  <th :style="getStyle(field)" > 金额</th>
                </tr>
                <tr v-for="(row, rowIndex) in field.fillData" :key="rowIndex">
                  <td  :width="20 + '%'" :style="{ color: field.printAttr && field.printAttr.fontColor }">
                    {{ row.accountName }}
                  </td>
                  <td  :width="20 + '%'" :style="{ color: field.printAttr && field.printAttr.fontColor }">
                    {{ row.bankAddr }}
                  </td>
                  <td  :width="20 + '%'" :style="{ color: field.printAttr && field.printAttr.fontColor }">
                    {{ row.bankAccountNo }}
                  </td>
                  <td  :width="20 + '%'" :style="{ color: field.printAttr && field.printAttr.fontColor }">
                    {{ row.way }}
                  </td>
                  <td  :width="20 + '%'" :style="{ color: field.printAttr && field.printAttr.fontColor }">
                    {{ row.money }}
                  </td>
                </tr>
              </table>
            </div>
            <!-- 明细类 表格类-->
            <div class="detail-table" v-else>
              <table border="1" cellspacing="0" cellpadding="8" v-if="field.fillData&&field.fillData.length">
                <tr>
                  <!-- 子集不显示 -->
                  <th
                    v-for="(detail, detailIndex) in fieldList(field)"
                    :key="detailIndex"
                    :style="getStyle(field)"
                  >
                    {{ detail.name }}
                  </th>
                </tr>
                <template v-for="(row, rowIndex) in field.fillData">
                  <table-tr :rowData="row" :field="field" :key="rowIndex"></table-tr>
                  <table-tr class="sub-set-tr" v-if="hasSubSet(field) && hasSubSet(field).printAttr.hide === 1" :rowData="row" :field="field" :isSubSet="true" :key="row.id"></table-tr>
                </template>
              </table>
            </div>

          </div>
        </template>
      </div>
      <div class='print-row print-approval-wrap' v-if="showApproval&&approvalMap&&approvalMap.length">
        <div class="field-item no-flex">
           <!-- 审批流程 -->
            <div class='detail-table' v-if="approval.approvalstyle == 2">
              <table border="1" cellspacing="0" cellpadding="0">
                <thead >
                  <tr >
                    <th colspan="1" rowspan="1">
                    审批时间
                    </th>
                    <th colspan="1" rowspan="1">
                    审批环节
                    </th>
                    <th colspan="1" rowspan="1">
                    审批人
                    </th>
                    <th colspan="1" rowspan="1">
                    审批结果
                    </th>
                    <th colspan="1" rowspan="1">
                    审批意见
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(trItem) in approvalMap" :key="trItem.uuid">
                      <td>{{trItem.time}}</td>
                      <td>{{trItem.section}}</td>
                      <td>{{trItem.approver}}</td>
                      <td>{{trItem.result}}</td>
                      <td v-if="trItem.approvalComment && trItem.approvalComment.indexOf('[img]') > -1" >
                        <img :src="getapprovalUrl(trItem)" width="100px" height="100px"/>
                      </td>
                      <td v-else>{{trItem.suggest||''}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <table border="1" cellspacing="0" cellpadding="0">
                <tbody>
                  <tr v-for="(trItem) in approvalMap" :key="trItem.uuid">
                      <td>审批环节：{{trItem.section}}</td>
                      <td>审批人：{{trItem.approver}}</td>
                      <td>审批意见：{{trItem.suggest}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";
// import printJS from "print-js";
import { getFileUrl } from "@/tools/getFileUrl";
import { printService } from "@/api";
import { BusinessFieldType } from "../../businessDetail/data/BusinessCommonHeader";
import tableTr from "./tableTr";
import TextCompose from "../../businessDetail/components/columnRendering/textComposition";

export default {
  name: "PrintHtml",
  componentName: "PrintHtml",
  components: {
    tableTr,
    TextCompose,
    VueBarcode
  },
  props: {
    renderInfo: {
      type: Object,
      default: () => JSON.parse(localStorage.getItem("ls_print_storage"))
    },
    businessData: {
      type: Object
    },
    checkTempalte: {
      type: Object,
      default: () => {}
    },
    detailId: {
      type: [String, Number]
    }
  },
  computed: {
    pageTitle() {
      return this.renderInfo?.pageTitle || {};
    },
    mdData() {
      return this.renderInfo?.mdData || {};
    },
    mdModel() {
      const completeField = this.renderInfo?.mdModel?.find((item) => item.fields.find((subItem) => this.isCheckBox(subItem)))?.fields[0];
      if (completeField && !this.mdData[completeField.field]) {
        return this.renderInfo?.mdModel.map((item) => {
          item.fields = item.fields.map((subItem) => {
            const { targetField } = completeField;
            const target = this.businessData._mainInfos[0].findMainAreaFieldByUuid(targetField);
            if (subItem.field === target.field) {
              subItem.isComplete = true;
            }
            return subItem;
          });
          return item;
        }) || [];
      }
      return this.renderInfo?.mdModel || [];
    },
    page() {
      return this.renderInfo?.pageTitle || {};
    },
    approval() {
      return this.renderInfo?.approval || undefined;
    },
    showApproval() {
      return this.approval && (String(this.page.allShow) === "1" || String(this.approval.hide) !== "0");
    },
    hasTextCompse() {
      return this.mdModel.find((item) => item.fields.find((subItem) => this.isTextCompose(subItem)));
    }
  },
  data() {
    return {
      printed: null,
      opened: false,
      approvalMap: []
    };
  },
  methods: {
    getapprovalUrl(b) {
      const sIndex = b.approvalComment.indexOf("[img]");
      const len = b.approvalComment.length;
      return getFileUrl(b.approvalComment.substring(sIndex, len).replace("[img]", ""), "000", "");
    },
    getColor(field) {
      return this.renderInfo.fieldAttr.find((item) => item.field === field)?.color;
    },
    getStyle(fields) {
      const { printAttr, field, isComplete } = fields;
      const alignMap = {
        1: "left",
        2: "center",
        3: "right"
      };

      return {
        color: printAttr.fontColor || this.getColor(field),
        fontWeight: printAttr.fontWeight && printAttr.fontWeight === "0" ? "bold" : "500",
        fontSize: printAttr && `${printAttr.fontSize}px`,
        textAlign: alignMap[printAttr.align] || "left",
        textDecoration: isComplete && "line-through"
      };
    },
    sendLoadImg() {
      console.log("logo is load");
      this.beforeStartPrint();
    },
    print() {
      this.printed = false;
      this.opened = true;
      // 如有图片类打印数据 则img load以后执行打印预览
      // 否则监听生命周期updated 执行打印预览
    },
    // 设置值的class
    setClass(field) {
      return `${field.field}_value`;
    },
    startPrint() {
      if (!this.printed) {
        this.printed = true;
        this.$emit("print", 1);
      }
    },
    targetFieldName(field) {
      const { targetField } = field;
      const target = this.businessData._mainInfos[0].findMainAreaFieldByUuid(targetField);
      return target?.name || "";
    },
    findColumnByField(fieldId) {
      return this.businessData._mainInfos[0].findColumnByField(fieldId);
    },
    // 图文合成
    isTextCompose(field) {
      return Number(field.fieldType) === BusinessFieldType.textComposition;
    },
    // 普通字段
    isDefault(field) {
       return !this.isCashObj(field) && !this.isTable(field) && !this.isCheckBox(field) && !this.isLine(field) && !this.isTextCompose(field);
    },
    isNoFlex(field) {
      return this.isCashObj(field) || this.isTable(field);
    },
    // 是否是纯文本
    // 是否里程碑字段
    isMileStone(field) {
      return Number(field.fieldType) === BusinessFieldType.mileStone;
    },
    // 是否是区域字段
    isArea(field) {
      // 区域
      return Number(field.fieldType) === 42;
    },
    // 收付对象
    isCashObj(field) {
      return Number(field.fieldType) === BusinessFieldType.cashObject;
    },
    // 明细 || 收付信息 || 关联数据 等表格展示的字段
    isTable(field) {
      const fieldType = Number(field.fieldType);
      return (
        fieldType === BusinessFieldType.detailParent
        || fieldType === BusinessFieldType.shouFu
        || fieldType === BusinessFieldType.associatedData
        || fieldType === 42
      );
    },
    // 是否是分割线
    isLine(field) {
      return Number(field.fieldType) === BusinessFieldType.line;
    },
    // 展示label
    showLabelField(field) {
      return (this.isTable(field) || this.isCashObj(field)) && field?.fillData?.length;
    },
    getSubSetIndex(field) {
      return field.sub.findIndex((item) => item.fieldType === BusinessFieldType.subSet);
    },
    hasSubSet(field) {
      const sub = field.sub || [];
      return sub.find((item) => Number(item.fieldType) === BusinessFieldType.subSet);
    },
    fieldList(field) {
      return field.sub?.filter((item) => {
        const fType = Number(item.fieldType);
        return fType !== BusinessFieldType.subSet && fType !== BusinessFieldType.attachment;// 子集和附件不打印
      }) || [];
    },
    isCheckBox(field) {
      return Number(field.fieldType) === BusinessFieldType.checkBox;
    },
    beforeStartPrint() {
      if (this.showApproval && !this.printed) {
        printService.mdQueryApproveList({ printId: this.checkTempalte.id, mainId: this.detailId }).then((resp) => {
          this.approvalMap = resp;
          this.$nextTick(() => {
            this.startPrint();
          });
        }).catch((error) => {
          console.log(error);
          this.startPrint();
        });
      } else {
        this.startPrint();
      }
    },
    formatValues(fieldInfo, row) {
      // console.log(fillData, fieldType, row);
      // eslint-disable-next-line prefer-const
      let { fillData, fieldType, field } = fieldInfo;
      // 明细 子集数据从row取
      const fieldTypeNum = Number(fieldType);
      if (row) {
        fillData = row[field];
      }
      if (!fillData && fillData !== 0) {
        if (fieldTypeNum === BusinessFieldType.pureText) {
          return `<div>${fieldInfo.subTitle}</div><div>${fieldInfo.captionDescription}</div>`;
        }
      }
      // 单选多选处理
      if ([BusinessFieldType.select, BusinessFieldType.multiSelect].includes(fieldTypeNum)) {
        return fillData && fillData?.map((item) => item.name).join(",");
      }
      if (fieldTypeNum === BusinessFieldType.address && fillData) {
        // 地址
        return fillData.provinceName + fillData.cityName + fillData.districtName + fillData.address;
      }
      // 附件 显示图片数量
      if (fieldTypeNum === BusinessFieldType.attachment) {
        return `(${fillData?.length})`;
      }

      return fillData;
    }
  },
  watch: {
    renderInfo: {
      handler(val) {
        console.log("watch render info:", val);
        this.renderInfo = val;
      }
    }
  },
  // mounted() {
  //   // 打印模板dom 移至body层
  //   this.$nextTick(() => {
  //     const body = document.querySelector("body");
  //     if (body.append) {
  //       body.append(this.$el);
  //     } else {
  //       body.appendChild(this.$el);
  //     }
  //   });
  // },
  updated() {
    if (this.pageTitle.printLogo !== 0 && !this.hasTextCompse) {
      this.beforeStartPrint();
    }
  }
};
</script>

<style scope lang="scss">
.print-html {
  position: relative;
  z-index: 100;
  width: 1080px;
  font-size: 16px;
  color: #606060;
  padding: 5px 0;

  /* height: 100%; */
  /* 后续删除 */
  // position: fixed;
  // z-index: 1001;
  // top: 20px;
  // left: 200px;
  // background-color: #fff;
  /* display: none; */
  .print-row {
    width: 100%;
    padding: 16px 0;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    .area {
      display: flex;
      flex-flow: row;
      width: 100%;
    }
    .area-block{
      width: 100%;
      .label{
        max-width: 30%;;
      }
    }
  }
  .field-item {
    display: flex;
    // align-items: center;
    flex: 1 1 0%;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    .label {
      padding-left: 10px;
      max-width: 30%;
      flex: 1,
      /*word-wrap: nowrap;*/
    }
    .value {
      padding: 0 5px;
      /*word-wrap: nowrap;*/
      width: 65%;
      flex: 1,
      // width: 240px;
    }
    .line,.text-compose{
      flex: 1;
    }
    /*被标记完成*/
    .complete {
      text-decoration: line-through!important;
    }
    &.no-flex {
      display: block;
      .label {
        width: 100%;
      }
      .detail-table {
        // margin-top: 10px;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
  table {
    width: 98%;
    box-sizing: border-box;
    border-collapse: collapse;
    thead{
      tr {
        th {
            padding: 16px 0;
          }
      }
    }
    tr {
      // border: 1px solid #e5e5e5;
      &.sub-set-tr {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        &:last-child {
          border-bottom: 1px solid #fff;
        }
        &>td {
          padding: 16px 0;
        }
      }
    }
  }
}

.print-title {
  font-size: 18px;
  width: 100%;
  padding: 24px 0;
  font-weight: bold;
  text-align: center;
  position: relative;
  .title-left{
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
  .title-rt{
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
}

.line-solid {
  // height: 2px;
  width: 100%;
  // background: #ECF1F7;
  border: 2px solid #ecf1f7;
}
</style>
