<template>
  <en-dialog
    title="选择导入模板"
    width="760px"
    :visible.sync="visible">
    <div class="import-data-dialog">
      <en-card
        v-for="(item, index) in enableTempList"
        :key="index"
        icon-name="bus-btn8_1"
        :title="item.name"
        :icon-bg="getIconBg(index)"
        icon-color="#fff"
        :dataInfo="item"
        @click.native.stop="openDialog(item)"
      >
      </en-card>
    </div>
  </en-dialog>
</template>

<script>

export default {
  name: "ImportTempListDialog",
  data() {
    return {
      enableTempList: [],
      visible: false
    };
  },
  methods: {
    getIconBg(index) {
      const colors = ["#fda700", "#73ade8", "#ff7e66", "#4fd2c2"];
      return colors[index % 4];
    },
    open(list, params) {
      this.params = params;
      this.visible = true;
      this.enableTempList = list;
    },
    openDialog(item) {
      this.$emit("openImportDialog", item);
      this.visible = false;
    }
  }
};
</script>

<style lang="scss">
.import-data-dialog {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  min-height: 200px;
  .en-card-normal {
    margin: 0 20px 10px 0;
    .en-card-normal-icon {
      display: flex;
    }
  }
}
</style>
