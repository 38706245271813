var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.opened
    ? _c("div", { staticClass: "print-html" }, [
        _c("div", { staticClass: "print-title" }, [
          _c(
            "span",
            {
              style: {
                color: _vm.pageTitle.titleColor,
              },
            },
            [_vm._v(_vm._s(_vm.mdData.templateName))]
          ),
          _c(
            "div",
            { staticClass: "title-left" },
            [
              _vm.pageTitle.printLogo === 0 && _vm.pageTitle.logoAlign === "1"
                ? _c("img", {
                    staticClass: "src",
                    attrs: {
                      src: require("@/assets/images/enlogo.png"),
                      alt: "",
                    },
                    on: { load: _vm.sendLoadImg },
                  })
                : _vm._e(),
              _vm.pageTitle.printCode === 0 && _vm.pageTitle.codeAlign === "1"
                ? _c("vue-barcode", {
                    attrs: {
                      value: _vm.mdData.code,
                      height: "35",
                      fontSize: "16",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "title-rt" },
            [
              _vm.pageTitle.printLogo === 0 && _vm.pageTitle.logoAlign === "3"
                ? _c("img", {
                    staticClass: "src",
                    attrs: {
                      src: require("@/assets/images/enlogo.png"),
                      alt: "",
                    },
                    on: { load: _vm.sendLoadImg },
                  })
                : _vm._e(),
              _vm.pageTitle.printCode === 0 && _vm.pageTitle.codeAlign === "3"
                ? _c("vue-barcode", {
                    attrs: {
                      value: _vm.mdData.code,
                      height: "35",
                      fontSize: "16",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "line-solid" }),
        _c(
          "div",
          { staticClass: "print-content" },
          [
            _vm._l(_vm.mdModel, function (row, rowIndex) {
              return _c(
                "div",
                { key: rowIndex, staticClass: "print-row" },
                [
                  _vm._l(row.fields, function (field, fieldIndex) {
                    return _c(
                      "div",
                      {
                        key: fieldIndex,
                        staticClass: "field-item",
                        class: { "no-flex": _vm.isNoFlex(field) },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label",
                            style: {
                              fontWeight:
                                (field.printAttr.fontWeight &&
                                  field.printAttr.fontWeight === "0") ||
                                _vm.showLabelField(field)
                                  ? "bold"
                                  : "500",
                              color:
                                (field.printAttr &&
                                  field.printAttr.fontColor) ||
                                _vm.getColor(field),
                              fontSize:
                                field.printAttr &&
                                field.printAttr.fontSize + "px",
                              display:
                                (field.printAttr.showPrefix === "0" ||
                                  field.printAttr.hide) &&
                                !_vm.showLabelField(field)
                                  ? "none"
                                  : "",
                            },
                          },
                          [_vm._v(" " + _vm._s(field.name) + "： ")]
                        ),
                        _vm.isDefault(field)
                          ? _c(
                              "div",
                              {
                                staticClass: "value",
                                style: _vm.getStyle(field),
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.formatValues(field)),
                                  },
                                }),
                              ]
                            )
                          : _vm.isLine(field)
                          ? _c("div", { staticClass: "line" }, [
                              field.printAttr.lineType == 1
                                ? _c("hr", {
                                    staticStyle: {
                                      "border-style": "dotted",
                                      height: "1px",
                                    },
                                  })
                                : field.printAttr.lineType == 2
                                ? _c("hr")
                                : _vm._e(),
                            ])
                          : _vm.isCheckBox(field)
                          ? _c("div", {}, [
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: { checked: !_vm.mdData[field.field] },
                              }),
                              _vm._v(_vm._s(_vm.targetFieldName(field)) + " "),
                            ])
                          : _vm.isMileStone(field)
                          ? _c("div", { staticClass: "line" })
                          : _vm.isArea(field)
                          ? _c(
                              "div",
                              { staticClass: "area" },
                              _vm._l(field.areas, function (area, areaIndex) {
                                return _c(
                                  "div",
                                  { key: areaIndex, staticClass: "field-item" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "area-block" },
                                      _vm._l(
                                        area.sub,
                                        function (sub, subIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: subIndex,
                                              staticClass: "field-item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "label",
                                                  style: {
                                                    fontWeight:
                                                      sub.printAttr
                                                        .fontWeight &&
                                                      sub.printAttr
                                                        .fontWeight === "0"
                                                        ? "bold"
                                                        : "500",
                                                    color:
                                                      (sub.printAttr &&
                                                        sub.printAttr
                                                          .fontColor) ||
                                                      _vm.getColor(sub.field),
                                                    fontSize:
                                                      sub.printAttr &&
                                                      sub.printAttr.fontSize +
                                                        "px",
                                                    display:
                                                      sub.printAttr
                                                        .showPrefix === "1"
                                                        ? "none"
                                                        : "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(sub.name) + "："
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "value",
                                                  style: _vm.getStyle(sub),
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatValues(sub)
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm.isTextCompose(field)
                          ? _c(
                              "div",
                              { staticClass: "text-compose" },
                              [
                                _c("text-compose", {
                                  attrs: {
                                    column: _vm.findColumnByField(field.field),
                                    "business-data": _vm.businessData,
                                  },
                                  on: {
                                    load: function ($event) {
                                      return _vm.sendLoadImg()
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm.isCashObj(field) &&
                            field.fillData &&
                            field.fillData.length
                          ? _c("div", { staticClass: "detail-table" }, [
                              _c(
                                "table",
                                {
                                  attrs: {
                                    border: "1",
                                    cellspacing: "0",
                                    cellpadding: "8",
                                  },
                                },
                                [
                                  _c("tr", [
                                    _c("th", { style: _vm.getStyle(field) }, [
                                      _vm._v(" 付款人名字"),
                                    ]),
                                    _c("th", { style: _vm.getStyle(field) }, [
                                      _vm._v(" 银行"),
                                    ]),
                                    _c("th", { style: _vm.getStyle(field) }, [
                                      _vm._v(" 账号"),
                                    ]),
                                    _c("th", { style: _vm.getStyle(field) }, [
                                      _vm._v(" 付款方式"),
                                    ]),
                                    _c("th", { style: _vm.getStyle(field) }, [
                                      _vm._v(" 金额"),
                                    ]),
                                  ]),
                                  _vm._l(
                                    field.fillData,
                                    function (row, rowIndex) {
                                      return _c("tr", { key: rowIndex }, [
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              color:
                                                field.printAttr &&
                                                field.printAttr.fontColor,
                                            },
                                            attrs: { width: 20 + "%" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(row.accountName) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              color:
                                                field.printAttr &&
                                                field.printAttr.fontColor,
                                            },
                                            attrs: { width: 20 + "%" },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row.bankAddr) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              color:
                                                field.printAttr &&
                                                field.printAttr.fontColor,
                                            },
                                            attrs: { width: 20 + "%" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(row.bankAccountNo) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              color:
                                                field.printAttr &&
                                                field.printAttr.fontColor,
                                            },
                                            attrs: { width: 20 + "%" },
                                          },
                                          [_vm._v(" " + _vm._s(row.way) + " ")]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              color:
                                                field.printAttr &&
                                                field.printAttr.fontColor,
                                            },
                                            attrs: { width: 20 + "%" },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row.money) + " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _c("div", { staticClass: "detail-table" }, [
                              field.fillData && field.fillData.length
                                ? _c(
                                    "table",
                                    {
                                      attrs: {
                                        border: "1",
                                        cellspacing: "0",
                                        cellpadding: "8",
                                      },
                                    },
                                    [
                                      _c(
                                        "tr",
                                        _vm._l(
                                          _vm.fieldList(field),
                                          function (detail, detailIndex) {
                                            return _c(
                                              "th",
                                              {
                                                key: detailIndex,
                                                style: _vm.getStyle(field),
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(detail.name) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._l(
                                        field.fillData,
                                        function (row, rowIndex) {
                                          return [
                                            _c("table-tr", {
                                              key: rowIndex,
                                              attrs: {
                                                rowData: row,
                                                field: field,
                                              },
                                            }),
                                            _vm.hasSubSet(field) &&
                                            _vm.hasSubSet(field).printAttr
                                              .hide === 1
                                              ? _c("table-tr", {
                                                  key: row.id,
                                                  staticClass: "sub-set-tr",
                                                  attrs: {
                                                    rowData: row,
                                                    field: field,
                                                    isSubSet: true,
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]),
                      ]
                    )
                  }),
                ],
                2
              )
            }),
            _vm.showApproval && _vm.approvalMap && _vm.approvalMap.length
              ? _c("div", { staticClass: "print-row print-approval-wrap" }, [
                  _c("div", { staticClass: "field-item no-flex" }, [
                    _vm.approval.approvalstyle == 2
                      ? _c("div", { staticClass: "detail-table" }, [
                          _c(
                            "table",
                            {
                              attrs: {
                                border: "1",
                                cellspacing: "0",
                                cellpadding: "0",
                              },
                            },
                            [
                              _vm._m(0),
                              _c(
                                "tbody",
                                _vm._l(_vm.approvalMap, function (trItem) {
                                  return _c("tr", { key: trItem.uuid }, [
                                    _c("td", [_vm._v(_vm._s(trItem.time))]),
                                    _c("td", [_vm._v(_vm._s(trItem.section))]),
                                    _c("td", [_vm._v(_vm._s(trItem.approver))]),
                                    _c("td", [_vm._v(_vm._s(trItem.result))]),
                                    trItem.approvalComment &&
                                    trItem.approvalComment.indexOf("[img]") > -1
                                      ? _c("td", [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.getapprovalUrl(trItem),
                                              width: "100px",
                                              height: "100px",
                                            },
                                          }),
                                        ])
                                      : _c("td", [
                                          _vm._v(_vm._s(trItem.suggest || "")),
                                        ]),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ])
                      : _c("div", [
                          _c(
                            "table",
                            {
                              attrs: {
                                border: "1",
                                cellspacing: "0",
                                cellpadding: "0",
                              },
                            },
                            [
                              _c(
                                "tbody",
                                _vm._l(_vm.approvalMap, function (trItem) {
                                  return _c("tr", { key: trItem.uuid }, [
                                    _c("td", [
                                      _vm._v(
                                        "审批环节：" + _vm._s(trItem.section)
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        "审批人：" + _vm._s(trItem.approver)
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        "审批意见：" + _vm._s(trItem.suggest)
                                      ),
                                    ]),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { colspan: "1", rowspan: "1" } }, [
          _vm._v(" 审批时间 "),
        ]),
        _c("th", { attrs: { colspan: "1", rowspan: "1" } }, [
          _vm._v(" 审批环节 "),
        ]),
        _c("th", { attrs: { colspan: "1", rowspan: "1" } }, [
          _vm._v(" 审批人 "),
        ]),
        _c("th", { attrs: { colspan: "1", rowspan: "1" } }, [
          _vm._v(" 审批结果 "),
        ]),
        _c("th", { attrs: { colspan: "1", rowspan: "1" } }, [
          _vm._v(" 审批意见 "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }