<!--
 * @Author: 梁平贤
 * @Date: 2020-06-16 14:44:40
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-06-18 17:03:12
 * @Description:
-->

<template>
  <div
    id="address-content"
  >
    <div v-if="column.addrFormatType <= 4" class="address-row">
      <div class="row">
        省/自治区/直辖市
      </div>
      <div
        v-if="column.addrFormatType <= 3"
        class="row row-margin"
      >
        市
      </div>
      <div
        v-if="column.addrFormatType <= 2"
        class="row row-margin"
      >
        区/县
      </div>
      <div
        v-if="column.addrFormatType <= 1"
        class="row row-margin address"
      >
        详细地址
      </div>
    </div>
  </div>
</template>

<script>
import { componentMixins } from "en-js";
import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMAddressComponent",
  mixins: [MxColumnData, componentMixins],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    findSectionParent() {
      return this._MxFindComponentUpward(this, "MiddleSection");
    }
  },
  beforeCreate() {
    this.$options.components.BusinessColumnRendering = () => import("..");
  }
};
</script>
<style scoped lang="scss">
#address-content {
  margin: 0;
  .address-row {
    padding: 7px 0;
    position: relative;
    display: flex;
    flex-flow: row;
    margin-left: 0;
    .row {
      flex-grow: 1;
      height: 32px;
      padding-left: 10px;
      line-height: 32px;
      @include border-line;
      @include rounded-corners;
      white-space: nowrap;
      background-color: #fff;
      &.address-disable {
        background-color: #fbfbfb;
      }
    }
    .address {
      flex-grow: 2;
    }
    .row-margin {
      margin-left: 12px;
    }
  }
}
</style>
