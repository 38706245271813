<!--
 * @Description:
 * @Author: 林山峰
 * @LastEditors: Please set LastEditors
 * @Date: 2024-01-30 11:50:26
 * @LastEditTime: 2024-02-29 19:19:45
 -->
 <template>
  <div :class="['attachment-box', 'border']">
    <!--本地文件上传-->
    <en-upload  :on-change="handleUploadChange"
      :on-progress="handleProgressChange" :on-exceed="handleExceed" :file-list="fileList" :limitNum="limitNum"
      :limitType="3" class="add-btn-box">
    </en-upload>
    <!-- 仅图片 缩略图片各种格式-->
    <div  class="files">
      <image-file v-for="(file, index) in showList" ref="imgFileMod" :key="file.id || file.uid || index" :data="{ ...file }"
        :type="file.type === '000' || file.dataType === '000' ? 'image' : 'file'"  class="file" direction="h"
        :delete-method="handleDelete" @click.native.stop="handleClickImage(index)">
      </image-file>
      <!-- 更多-->
      <image-file type="more" v-if="moreList.length"  :moreNum="moreList.length" direction="h">
        <template #more>
          <div class="more-list">
            <image-file v-for="(file, index) in moreList" :data="{ ...file }" :key="file.id || file.uid || index"
              class="file" direction="v" :delete-method="handleDelete"
              @click.native.stop="handleClickImage(index + showList.length)">
            </image-file>
          </div>
        </template>
      </image-file>
    </div>
    <preview-dialog ref="preview" :file-list.sync="fileList" :title="column ? column.name : undefined" :mode="mode"
      :mdConfig="column" @handleDelete="handleDelete"></preview-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { fileService } from "@/api/businessModel";
import { getFileUrl } from "@/tools/getFileUrl";
import ImageFile from "@/components/en-attachment/views/imageFile";
import PreviewDialog from "@/components/en-attachment/dialog/previewDialog";
import Data from "@/components/en-attachment/data/data";
import Index from "@/components/en-attachment/data/utils";
import MxColumnData from "../MxColumnData.js";
import { AssignmentOptions } from "../../../data/BusinessCommonHeader.js";

export default {
  name: "BMImageComponent",
  components: { ImageFile, PreviewDialog },
  mixins: [MxColumnData, Data],
  provide() {
    return { $attachment: this };
  },
  data() {
    return {
      visible: false,
      maxShowNum: 3, // 测试
      fileList: [],
      fileIcon: {
        txt: { icon: "fujian-txt", color: "#21A3E6" },
        xls: { icon: "fujian-excel", color: "#4AAE2D" },
        xlsx: { icon: "fujian-excel", color: "#4AAE2D" },
        docx: { icon: "fujian-Word", color: "#537CE7" },
        doc: { icon: "fujian-Word", color: "#537CE7" },
        ppt: { icon: "fujian-PPT", color: "#FB7A26" },
        pdf: { icon: "fujian-pdf", color: "#EC1C32" }
      }
    };
  },
  props: {
    // data: Array, // 原始已存在的数据
    column: {
      type: Object,
      default() {
        return {};
      }
    },
    border: {
      type: Boolean,
      default: false
    },
    mode: {
      // 显示模式 (edit show)
      type: String,
      default: "show"
    },
    displayGrid: { // 是否是列表展示
      type: Boolean,
      default: false
    },
    calcMaxShowNum: { // 是否计算可展示最大值
      type: Boolean,
      default: false
    }
  },
  watch: {
  },
  computed: {
    showList() {
      return this.fileList.slice(0, this.maxShowNum).filter((item) => item);
    },
    moreList() {
      return this.fileList.length > this.maxShowNum ? this.fileList.slice(this.maxShowNum) : [];
    },
    allImageList() {
      const arr = Index.getImageList(this.fileList);
      arr.forEach((itm) => {
        // if (!itm.url) {
        itm.url = getFileUrl(itm.fileCode || itm.code, itm.type || itm.dataType, itm.name);
        // }
      });
      return arr;
    },
    allFileList() {
      return Index.getFileList(this.fileList);
    }
  },
  methods: {
    handleUploadChange({ fileList, isAllUpload }) {
      if (this.checkLimitNum(fileList.length)) {
        return;
      }
      this.handleAddNewFiles(fileList, isAllUpload);
    },
    handleProgressChange() {
      this.fileList.splice(this.fileList.length);
    },
    async handleAddNewFiles(fileList, isAllUpload = true) {
      // 处理我的文件夹 选择的文件
      const folderFile = fileList.filter((obj) => Object.prototype.hasOwnProperty.call(obj, "folderId"));
      const folderCode = [];
      folderFile.forEach((file) => {
        folderCode.push(file.fileUrl);
      });
      if (folderCode.length) {
        const res = await fileService.replaceFileCode({
          fileCodeList: JSON.stringify(folderCode)
        });

        if (!res.data) {
          let msg = res.msg;
          if (msg === "文件已删除或者地址已经改变") {
            msg = "模板文件已删除或者地址已经改变，请联系管理员";
          }
          this.$message.error(msg);
          return;
        }

        folderFile.forEach((itm) => {
          itm.fileCode = res.data[itm.fileUrl];
          itm.code = itm.fileCode;

          const index = fileList.findIndex((f) => f.fileUrl === itm.fileUrl);
          fileList.splice(index, 1, itm);
        });
      }

      fileList.forEach((itm) => {
        itm.type = itm.type || itm.dataType;
      });
      this.fileList = Index.mergeFileList(fileList, this.fileList);
      this.fileList.splice(this.fileList.length);
      if (isAllUpload) {
        // this.$emit("change", this.fileList);
      this.column.updateValueWithOptions(fileList, AssignmentOptions.manual);
      }
    },
    handleDelete(file) {
      const index = Index.indexOfFile(this.fileList, file);
      this.fileList.splice(index, 1);
      this.$emit("deleteFile", file);
    },
    // handleProgress({ file, progress }) {
    //   console.log(`上传进度:${progress}   文件名称${file ? file.name : ""}`);
    // },
    // 查看图片
    handleClickImage(index = 0) {
      if (this.allImageList.length) {
        this.$viewImage({
          datas: this.allImageList,
          url: "url",
          thumUrl: "url",
          initialViewIndex: index
        });
      } else {
        this.$refs.preview.open();
      }
    },
    handleExceed() {
      const { name } = this.column;
      Message({ message: `字段（${name}）上传数量不能超过${this.limitNum}`, type: "error" });
    },
    /**
     * @description: 校验上传文件的数量
     * @param {*} len 即将上传的文件数量
     */
    checkLimitNum(len) {
      const { limitNumType, limitNum, name } = this.column;
      if (Number(limitNumType) === 2) { // 数量限制单个
        Message({
          type: "error",
          message: `字段（${name}）上传数量不能超过1`
        });
        return true;
      }
      if (Number(limitNumType) === 3) { // 自定义
        const hasLen = this.data.length;
        const willLen = len;
        if (hasLen + willLen > Number(limitNum)) {
          Message({
            type: "error",
            message: `字段（${name}）上传数量不能超过${limitNum}`
          });
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
  }
};
</script>
<style scoped lang="scss">
.file-other {
  cursor: pointer;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: #F5F8FC;
  border: 1px solid #E8ECF2;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #A9B5C6;
}

.annex {
  cursor: pointer;
  margin-left: 5px;
  width: 190px;
  height: 26px;
  background: #F5F8FC;
  border: 1px solid #E8ECF2;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .annex-icon {
    margin-left: 12px 0 11px;
    display: flex;
    align-items: center;
  }

  .annex-name {
    margin-left: 5px;
    width: 139px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1B1C1E;
    white-space: nowrap;
    /* 禁止文本换行 */
    overflow: hidden;
    /* 隐藏超出父元素宽度的文本 */
    text-overflow: ellipsis;
    /* 当文本溢出时显示省略号 */
  }
}

.image-list-item {
  width: 34px;
  height: 34px;
  background: #ECF1F7;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center
}

.border {
  border: 1px solid $field-border-color;
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 32px;

  &:hover {
    border-color: $field-border-color-hover;
  }
}

.attachment-box {
  background-color: white;
  font-size: 12px;
  display: flex;
  align-items: center;

  //添加按钮
  .add-btn-box {
    margin: 0 5px;
    font-size: 12px;
  }

  .icon-box {
    margin-left: 8px;
    display: flex;
  }

  //文件列表
  .files {
    display: flex;
    align-items: center;
    padding: 2px 2px;
  }

}
</style>
<style>
.more-list {
  padding: 5px 0;
}
</style>
