<!--
 * @Description: 字段渲染统一的入口  比较奇怪,v-if和v-show一起用. 需求要求不展示的字段也需要校验,有些自己加的流程玩意就v-if吧
 * @Author: 梁平贤
 * @LastEditors: Please set LastEditors
 * @Date: 2019-09-11 11:30:49
 * @LastEditTime: 2024-02-29 09:44:44
 -->
<template>
  <el-form-item
    v-if="forceRendering"
    v-show="columnDisplayable"
    ref="formItem"
    :required="column.required"
    :prop="checkProp"
    :label="!hideLabel ? showFormItemName : ''"
    :label-width="!hideLabel ? leftColummNameLength : '0'"
    :label-position="columnAlignment"
    class="business-column-rendering"
    :class="className"
    :rules="submitRules"
    :style="columnStyleWidth"
  >
    <div slot="label" :style="fromItemStyle">{{ !hideLabel ? showFormItemName : '' }}</div>
    <component
      v-if="column.fieldType !== subSet"
      :is="_renderingComponent"
      :column="column"
      :row-index="rowIndex"
      :isValid="isValid"
      :business-data="businessData"
      :checkProp="checkProp"
    ></component>
    <!-- 子集需自定义 -->
    <slot v-else>
      子集slot自定义区
    </slot>
  </el-form-item>
</template>
<script>
import MxColumnData from "./MxColumnData.js";
import MxSubmitRules from "../../mixins/MxSubmitRules.js";
import { components, renderingComponent } from "./componentsConfig";
import { BusinessFieldType } from "../../data/BusinessCommonHeader";

export default {
  name: "BusinessColumnRendering",
  inject: ["$businessDetail"],
  components,
  mixins: [MxColumnData, MxSubmitRules],
  props: {
    // 绑定的数据源
    column: {
      type: Object,
      default() {
        return {};
      }
    },
    // 明细第几行
    rowIndex: {
      type: Number,
      default: null
    },
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    // prop校验路径
    checkProp: {
      type: String,
      default: "column"
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      subSet: BusinessFieldType.subSet,
      fieldPath: ""
    };
  },
  methods: {
    getFieldPath() {
      const path = [];
      path.unshift(this.column.field);
      let parentComponent = this.$parent;
      while (parentComponent) {
        if (parentComponent.$options.name === "BusinessColumnRendering") {
          path.unshift(parentComponent.column.field);
        }
        parentComponent = parentComponent.$parent;
      }
      this.fieldPath = path.join(".");
    },
    setLabelColor() {
      // 设置label的颜色
      const labelEl = this.$refs.formItem?.$el.querySelector(".el-form-item__label");
      if (labelEl) {
        labelEl.style.color = this.prominentStyle.color || this.column.color || "#333333";
        labelEl.style.fontWeight = this.prominentStyle["font-weight"];
        labelEl.style.textDecoration = this.prominentStyle["text-decoration"];
      }
    }
  },
  mounted() {
    this.setLabelColor();
    this.getFieldPath();
  },
  watch: {
    // 触发输入限制
    "column.triggerInputLimitWatch": function triggerInputLimitWatch() {
      console.log("触发了输入限制");
      this.$refs.formItem.$emit("el.form.blur");
    },
    prominentStyle() {
      this.setLabelColor();
    }
  },
  computed: {
    // 强制展示的, return false为不展示 流程不展示不渲染
    forceRendering() {
      if (this.column.fieldType === BusinessFieldType.seeFlow && !this.columnDisplayable) {
        return false;
      }
      return true;
    },
    columnDisplayable() {
      if (this.column.pageHide === 0) {
        return false;
      }
      if (this.column.fieldType === "6" || this.column.fieldType === 6) {
      if (!this.$route.query.id && (this.column.loadType === "2" || this.column.loadType === 2)) { //  新增
        return true;
      }
      if (this.$route.query.id) { //  编辑
        return true;
      }
      }

      if (this.column.field && this.column.findColumnByField(this.column.field)) {
        return this.column.findColumnByField(this.column.field).displayable; // 再查下一遍的原因是因为明细区行数据对应的是fields字段  数据更新后可能会不准确，需要来自sub属性中的源头数据
      }
      return this.column.displayable;
    },
    validatorData() {
      return this.$businessDetail?.validatorData || {};
    },
    // 关联显示优化XQTJ202009030002 是否通过校验
    isValid() {
      return this.validatorData.controlField !== this.fieldPath;
    },
    // 控制渲染的
    _renderingComponent() {
      return renderingComponent(this.column.fieldType);
    }
  }
};

</script>

<style scoped lang="scss">
  .business-column-rendering {
    display: flex;
    flex-flow: row;
    min-width: 200px;
    margin-bottom: 0;
    & /deep/ .el-form-item__label {
      flex-shrink: 0

    }
    & /deep/ .el-form-item.is-error .el-input__inner{
      border-color: #F56C6C!important;
    }
    /*label-width:auto需要这么重置*/
    & /deep/ .el-form-item__label-wrap {
      margin-left: 0 !important;
      flex-shrink: 0;
      .el-form-item__label{
        color: #333333 ;
      }
    }
    & /deep/ .el-form-item__content {
      flex: 1;
      margin-left: 0 !important;
      width: 0;
    }
  }
</style>
