var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "address-content" } }, [
    _vm.column.addrFormatType <= 4
      ? _c("div", { staticClass: "address-row" }, [
          _c("div", { staticClass: "row" }, [_vm._v(" 省/自治区/直辖市 ")]),
          _vm.column.addrFormatType <= 3
            ? _c("div", { staticClass: "row row-margin" }, [_vm._v(" 市 ")])
            : _vm._e(),
          _vm.column.addrFormatType <= 2
            ? _c("div", { staticClass: "row row-margin" }, [_vm._v(" 区/县 ")])
            : _vm._e(),
          _vm.column.addrFormatType <= 1
            ? _c("div", { staticClass: "row row-margin address" }, [
                _vm._v(" 详细地址 "),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }