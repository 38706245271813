var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "detail-table",
      class: { "is-ping": !_vm.isTiled, "is-list": _vm.isTiled },
    },
    [
      _c(
        "en-table",
        {
          ref: "table",
          attrs: {
            border: _vm.isTiled,
            "head-end-config": _vm.headAddConfig,
            "auto-resize": "",
            data: _vm.column.subDetails,
            loading: _vm.column.tableLoading,
            "show-header": _vm.isTiled,
            "span-method": _vm.arraySpanMethod,
            "no-data-icon-size": "small",
            "max-height": "400",
            "row-style": _vm.tableRowStyle,
            "header-cell-style": _vm.headCellStyle,
            "cell-class-name": _vm.cellClassFormat,
            "drag-config": { dragRow: false },
            "hover-row-config": _vm.lineEditConfig,
            optimization: { scrollY: { gt: _vm.column.editable ? 20 : 100 } },
          },
          on: { "filter-sort-change": _vm.handleTableFilterChange },
        },
        [
          _c("en-table-column", { attrs: { width: "50", type: "index" } }),
          _vm._l(_vm.column.sub, function (row, lineIndex) {
            return [
              _c(
                "en-table-column",
                {
                  key: row.name,
                  attrs: {
                    width: _vm.columnStyleWidth(row),
                    "filter-sort-config": _vm.filterConfig(row.fieldType),
                    data: _vm.getData(row),
                    "min-width": _vm.columnStyleWidth(row) == "auto" ? 220 : "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "edit",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "wrapper is-edit" },
                              [
                                _c(
                                  "business-column-rendering",
                                  {
                                    attrs: {
                                      "hide-label": true,
                                      column: scope.row.fields[lineIndex],
                                      "row-index": scope.rowIndex,
                                      "business-data": _vm.businessData,
                                      "check-prop":
                                        _vm.checkProp +
                                        ".subDetails." +
                                        scope.rowIndex +
                                        ".fields." +
                                        lineIndex,
                                    },
                                  },
                                  [
                                    [
                                      _c("sub-set", {
                                        ref: "subSet",
                                        refInFor: true,
                                        attrs: {
                                          column: scope.row.fields[lineIndex],
                                          businessData: _vm.businessData,
                                          row: scope.row,
                                        },
                                        on: {
                                          handleShowExpand:
                                            _vm.handleShowExpand,
                                        },
                                      }),
                                    ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("span", [_vm._v(_vm._s(row.name))]),
                    row.requrein === 0
                      ? _c("span", { staticClass: "head-requred" }, [
                          _vm._v("*"),
                        ])
                      : _vm._e(),
                    row.suffix
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              row.suffix === "1"
                                ? "(元)"
                                : row.suffix === "2"
                                ? "(万元)"
                                : `(${row.suffix})`
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                2
              ),
            ]
          }),
          !_vm.isTiled
            ? _c("en-table-column", {
                staticClass: "tiled-cloumn",
                attrs: { "min-width": "280" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "wrapper-tile" },
                            [
                              _vm._l(
                                scope.row.fields,
                                function (lineColumn, lineIndex) {
                                  return [
                                    _vm.isShowField(lineColumn.field)
                                      ? [
                                          _c(
                                            "business-column-rendering",
                                            {
                                              key: lineColumn.uuid,
                                              attrs: {
                                                column: lineColumn,
                                                "business-data":
                                                  _vm.businessData,
                                                "check-prop":
                                                  _vm.checkProp +
                                                  ".subDetails." +
                                                  (scope.seq - 1) +
                                                  ".fields." +
                                                  lineIndex,
                                              },
                                            },
                                            [
                                              [
                                                _c("sub-set", {
                                                  ref: "subSet",
                                                  refInFor: true,
                                                  attrs: {
                                                    column: lineColumn,
                                                    businessData:
                                                      _vm.businessData,
                                                    row: scope.row,
                                                  },
                                                  on: {
                                                    handleShowExpand:
                                                      _vm.handleShowExpand,
                                                  },
                                                }),
                                              ],
                                            ],
                                            2
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  921309617
                ),
              })
            : _vm._e(),
          _vm.hasSubSet
            ? _c("en-table-column", {
                attrs: { width: "1", type: "expand" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.subSetOptions(scope.row)
                            ? _c("bm-subSet", {
                                key: _vm.subSetOptions(scope.row).field,
                                staticClass: "bm-subSet-expand",
                                attrs: {
                                  expandStyle: _vm.expandStyle,
                                  column: _vm.subSetOptions(scope.row),
                                  isTiled: _vm.isTiled,
                                  "business-data": _vm.businessData,
                                  checkProp:
                                    _vm.checkProp +
                                    ".subDetails." +
                                    (scope.seq - 1) +
                                    ".fields." +
                                    _vm.subSetFindIndex(scope.row),
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3649053545
                ),
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "en-dialog",
        {
          staticClass: "add-dialog",
          attrs: { visible: _vm.dialogVisible, width: "35%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _vm._v(" 批量添加 "),
              !!_vm.column.subDetails.length
                ? _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top-start",
                        "popper-class": "info-popover",
                        width: "400",
                        trigger: "hover",
                      },
                    },
                    [
                      _c("ul", { staticClass: "info-list" }, [
                        _c("li", { staticClass: "num1" }, [
                          _vm._v("覆盖新增 "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(
                              "原有数据被删除，此次选择的数据将替代原有数据"
                            ),
                          ]),
                        ]),
                        _c("li", { staticClass: "num2" }, [
                          _vm._v("依次新增 "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v("此次选择的数据累加在原有数据后方"),
                          ]),
                        ]),
                      ]),
                      _c(
                        "span",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [
                          _c("en-icon", {
                            staticClass: "pop-icon",
                            attrs: {
                              name: "tishi",
                              size: "14",
                              color: "#fac135",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "input-line-wrap" },
            [
              _c("span", [_vm._v("行数")]),
              _vm._v(" "),
              _c("en-input-number", {
                staticClass: "add-line-input",
                attrs: { placeholder: "请输入", size: "small" },
                model: {
                  value: _vm.addLineNums,
                  callback: function ($$v) {
                    _vm.addLineNums = _vm._n($$v)
                  },
                  expression: "addLineNums",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "en-button",
                {
                  attrs: { type: "green", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.batchAddLine(2)
                    },
                  },
                },
                [_vm._v("依次新增")]
              ),
              _c(
                "en-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.batchAddLine(
                        !!_vm.column.subDetails.length ? 1 : 2
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(!!_vm.column.subDetails.length ? "覆盖新增" : "新增")
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }