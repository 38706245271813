var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "shou-fu-content" } }, [
    _c("div", { staticClass: "name" }, [
      _c("span", { staticStyle: { "vertical-align": "middle" } }, [
        _vm._v(" " + _vm._s(_vm.column.name)),
      ]),
    ]),
    _c("div", { staticClass: "shou-fu-row" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }