<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-09-11 14:14:44
 * @LastEditTime: 2020-06-18 15:30:34
 -->
<template>
  <div
    id="shou-fu-content"
  >
    <div class="name">
      <span style="vertical-align:middle"> {{ column.name }}</span>
    </div>
    <div class="shou-fu-row">
      <!--<business-column-rendering-->
      <!--v-for="column1 in column.sub"-->
      <!--:key="column1.uuid"-->
      <!--class="column"-->
      <!--:column="column1"-->
      <!--:business-data="businessData"-->
      <!--/>-->
    </div>
  </div>
</template>

<script>
import { componentMixins } from "en-js";
import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMShouFuComponent",
  mixins: [MxColumnData, componentMixins],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    findSectionParent() {
      return this._MxFindComponentUpward(this, "MiddleSection");
    }
  },
  beforeCreate() {
    this.$options.components.BusinessColumnRendering = () => import("..");
  }
};
</script>
<style scoped lang="scss">
#shou-fu-content {
  width: 100%;
  border: 1px solid #e8ecf2;
  border-radius: 4px;
  margin: 0;
  .name {
    font-weight: bold;
    border-bottom: 1px solid #e8ecf2;
    background-color: #f5f7fa;
    height: 48px;
    line-height: 48px;
    padding-left: 21px;
  }
  .shou-fu-row {
    overflow-x: auto;
    padding: 7px 11px;
    position: relative;
    display: flex;
    flex-flow: row;
    margin-left: 0;
    .column {
      flex: 1;
      // padding: 10px;
    }
  }
}
</style>
