<!--
 * @Descripttion: 数据导入
 * @Author: 彭博
 * @Date: 2020-07-30 16:25:58
 * @LastEditors: wuqi
 * @LastEditTime: 2021-04-26 12:00:27
-->
<template>
  <div class="import-add">
    <en-dialog :visible="showDialog" :title="title" en-body-scroll @close="showDialog = false">
        <ul class='setup-list'>
          <li>
            <div class='item-content'>
              <span>1、请先下载数据模板</span>
              <en-button type='green' class='btn-item' @click="dowmTemplate">立即下载模板</en-button>
            </div>
          </li>
          <li>
            <div class='item-content'>
              <span>2、在数据导入模板中,按照格式填充需导入的数据内容，并核对无误</span>
            </div>
          </li>
          <li>
            <div class='item-content'>
              <span>3、请选择导入类型</span>
              <el-radio-group v-model="importType" class='btn-item'>
                <el-radio :label="1">依次新增</el-radio>
                <el-radio :label="2">覆盖新增</el-radio>
              </el-radio-group>
            </div>
          </li>
          <li>
            <div class='item-content' v-if="!uploadFile">
              <span>4、上传填充好的数据导入模板(仅支持扩展名xls、xlsx)</span>
              <!-- <en-button  class='btn-item'>立即上传模板</en-button> -->
              <el-upload

                ref="upload"
                :action="uploadUrl+'/mdDataDetailImport.app'"
                :data='uploadParams'
                class="upload_lay"
                name="importFile"
                accept=".xlsx,.xls"
                :show-file-list='false'
                :on-success="handleSuccess"
                :on-error='handleError'
                :on-progress='handleProgress'
              >
                <en-button   size="small" class='btn-item'>
                  立即上传模板
                </en-button>
              </el-upload>

            </div>
            <div class='item-content upload-process' v-if="uploadFile">
                <en-upload-progress
                  :iconColor='suffix(uploadFile.name).iconColor'
                  :iconName='suffix(uploadFile.name).iconName'
                  :fileName='uploadFile.name'
                  :progress-status="uploadStatus"  :percent='uploadFile.percentage'></en-upload-progress>
                  <i class='el-icon-error delete-upload' @click="uploadFile=null"></i>
                  <span v-if="importError">数据导入失败，请重新导入 <el-button type="text" @click="dowmImportResult">查看错误日志</el-button></span>
              </div>
          </li>
        </ul>
        <span slot="footer">
        </span>
    </en-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { businessService } from "@/api/businessModel";
import folderComm from "@/mixins/myFolder/folderComm.js";
import { apiBaseUrl } from "../../../../../../../baseUrl";

export default {
  name: "ImportAdd",
  componentName: "ImportAdd",
  props: {
    title: String,
    column: Object
  },
  mixins: [folderComm],
  data() {
    return {
      showDialog: false,
      importError: false,
      importType: 1,
      uploadFile: null, // 当前上传的文件
      uploadStatus: "", // 当前上传状态
      uploadParams: {}
    };
  },
  computed: {
    ...mapGetters(["urlParam", "tenantId", "appSessionId"]),
    uploadUrl() {
      return apiBaseUrl;
    }
  },
  created() {
    this.uploadParams = {
      channelType: "PC",
      channelcode: "PC",
      // appKey: this.urlParam.appKey,
      // token: this.urlParam.token,
      // ts: this.urlParam.ts,
      appSessionId: this.appSessionId,
      tenantId: this.tenantId,
      templateId: this.column.templateId,
      version: this.column.version,
      field: this.column.field
    };
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    async dowmTemplate() {
      await businessService.exportDetailTemplate({ templateId: this.column.templateId, version: this.column.version, field: this.column.field });
    },
    async dowmImportResult() {
      await businessService.exportImportExcelResult({ templateId: this.column.templateId, tenantId: this.tenantId });
    },
    handleSuccess(data, file, fileList) {
      console.log(data, file, fileList);
      if (data && data.msgType === "N") {
        if (data.rspData) {
            if (data.rspData.error) {
                this.importError = true;
            } else {
              this.showDialog = false;
              this.$emit("importSuccess", this.importType, data.rspData?.rspData?.dataList);
              this.uploadFile = null;
            }
        } else {
            this.$message.info("无数据");
            this.uploadFile = null;
        }
      } else {
          this.$message.error(data.rspMsg);
          this.uploadFile = null;
      }
    },
    handleProgress(event, file, fileList) {
      console.log(event, file, fileList);
      this.uploadFile = file;
      this.uploadStatus = "loading";
    },
    handleError() {
      this.uploadStatus = "error";
    }
  }
};
</script>

<style lang="scss" scoped>
.setup-list{
    border-left: 1px solid #cdcdcd;
    padding-left: 18.5px;
    color: #12b7f5;
    font-size: 30px;
    li{
      list-style: disc;
      border: 1px solid #dce5ec;
      padding: 22px;
      margin: 20px 0;
      border-radius: 3px;
      .item-content{
        color: #000;
        font-size: 14px;
      }
      .upload_lay{
        display: inline-block;
      }
      .btn-item{
        margin-left: 10px;
      }
      .upload-process{
        position: relative;
        display: inline-block;
        .delete-upload{
          position: absolute;
          font-size: 16px;
          right: -8px;
          top: -8px;
          color: red;
          cursor: pointer;
        }
      }
    }
}
</style>
