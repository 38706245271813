var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["attachment-box", "border"] },
    [
      _c("en-upload", {
        staticClass: "add-btn-box",
        attrs: {
          "on-change": _vm.handleUploadChange,
          "on-progress": _vm.handleProgressChange,
          "on-exceed": _vm.handleExceed,
          "file-list": _vm.fileList,
          limitNum: _vm.limitNum,
          limitType: 3,
        },
      }),
      _c(
        "div",
        { staticClass: "files" },
        [
          _vm._l(_vm.showList, function (file, index) {
            return _c("image-file", {
              key: file.id || file.uid || index,
              ref: "imgFileMod",
              refInFor: true,
              staticClass: "file",
              attrs: {
                data: { ...file },
                type:
                  file.type === "000" || file.dataType === "000"
                    ? "image"
                    : "file",
                direction: "h",
                "delete-method": _vm.handleDelete,
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClickImage(index)
                },
              },
            })
          }),
          _vm.moreList.length
            ? _c("image-file", {
                attrs: {
                  type: "more",
                  moreNum: _vm.moreList.length,
                  direction: "h",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "more",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "more-list" },
                            _vm._l(_vm.moreList, function (file, index) {
                              return _c("image-file", {
                                key: file.id || file.uid || index,
                                staticClass: "file",
                                attrs: {
                                  data: { ...file },
                                  direction: "v",
                                  "delete-method": _vm.handleDelete,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleClickImage(
                                      index + _vm.showList.length
                                    )
                                  },
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3509339385
                ),
              })
            : _vm._e(),
        ],
        2
      ),
      _c("preview-dialog", {
        ref: "preview",
        attrs: {
          "file-list": _vm.fileList,
          title: _vm.column ? _vm.column.name : undefined,
          mode: _vm.mode,
          mdConfig: _vm.column,
        },
        on: {
          "update:fileList": function ($event) {
            _vm.fileList = $event
          },
          "update:file-list": function ($event) {
            _vm.fileList = $event
          },
          handleDelete: _vm.handleDelete,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }