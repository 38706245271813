<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-09 16:55:50
 * @LastEditTime: 2020-06-18 15:25:48
 -->
<template>
  <div>
    <el-select
      v-model="column.name"
      placeholder="请选择"
      class="select"
    >
    </el-select>
  </div>
</template>

<script>
import MxColumnData from "../MxColumnData.js";
import { SelectColumn } from "../../../data/columns";

export default {
  name: "BMSelectComponent",
  mixins: [MxColumnData],
  props: {
    column: {
      type: SelectColumn,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        }
      ]
    };
  },
  methods: {}
};
</script>
<style scoped>
.select {
  width: 100% !important;
}
.select >>> .el-input {
  width: 100% !important;
}
</style>
