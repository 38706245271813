<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-09 18:19:26
 * @LastEditTime: 2020-06-18 18:28:11
 -->
<template>
  <el-input v-model="column.value"></el-input>
</template>
<script>

export default {
  name: "BMInputComponent",
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mounted() {
    // 默认值逻辑

  }
};
</script>
<style scoped lang="scss">

</style>
