var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-input-number",
    {
      style: "color:" + _vm.column.showColor,
      model: {
        value: _vm.column.value,
        callback: function ($$v) {
          _vm.$set(_vm.column, "value", $$v)
        },
        expression: "column.value",
      },
    },
    [
      _vm.column.suffix.length > 0
        ? _c("template", { slot: "suffix" }, [
            _vm._v(" " + _vm._s(_vm.column.suffix) + " "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }