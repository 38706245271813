/*
 * @Description: 提交的校验规则,根据column生成
 * @Author: 梁平贤
 * @Date: 2020-06-23 13:33
 */

const MxSubmitRules = {

  computed: {
    submitRules() {
      const checkColumnSubmit = (rule, value, callback) => {
        let re = true;
        if (this.businessData.checkType === 0) {
          // 不校验
          callback();
        } else if (this.businessData.checkType === 1) {
          // 全部校验
          re = this.column.checkCouldSubmit;
        } else if (this.businessData.checkType === 2) {
          // 校验输入限制
          re = this.column.inputLimitCheck();
        }
        // const re = this.column.checkCouldSubmit;
        console.log(re);
        if (re instanceof Error) {
          re.errorColumn = this.column;
          return callback(re);
        }
        return callback();
      };

      return [{
        validator: checkColumnSubmit,
        trigger: ["blur", "change"]
      }];
    }
  }

};

export default MxSubmitRules;
