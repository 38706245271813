<!--
 * @Descripttion: 卡片式新增
 * @Author: 彭博
 * @Date: 2020-07-28 16:14:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-29 09:05:39
-->
<template>
  <div class="card-add">
    <en-dialog :visible="showDialog"
                :title="title"
                en-body-scroll
                @close="showDialog=false">
      <el-form ref="dForm" label-position="left" label-width="auto" :model="formParams">
        <div class="wrapper">
          <business-column-rendering
            v-for="lineColumn in cardOptions.fields"
            :key="lineColumn.uuid"
            :column="lineColumn"
            :business-data="cardOptions"

          />
          <div class="tip">
            <!-- <div class="icon"> -->
            <en-icon name="icontishi" size="small" ></en-icon>
            <!-- </div> -->
            <div class="text">注：对当前选项进行多选时，就能新增生成多条数据</div>
          </div>
        </div>
      </el-form>
       <div slot="footer">
         <en-button @click="confirmCheck" class="assist-btn">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import { request } from "en-js"; // 引入公共方法
import { detailListService } from "@/api/businessModel";

export default {
  name: "SelectAdd",
  componentName: "SelectAdd",
  props: {
    title: String,
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    column: {
      type: Object,
      require: true,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      showDialog: false, // 显示弹窗
      formParams: {},
      cardOptions: {}, // column 数据集合
      cardDetail: [] // 数据value
    };
  },
  methods: {
    openDialog(options) {
      this.cardOptions = options; // 保存 数据集合
      this.showDialog = true;
    },
    // 获取当前输入值集合
    getCardDetail() {
      // 遍历 获取值
      if (!this.cardOptions.fields.length) { return; }

         /* eslint-disable */
      this.cardDetail = this.cardOptions.fields.map((column) => column.paramsForSubmit)
        .filter((values) => !!values)
          .map((item) => {
            const key = Object.keys(item)[0]; // field
            const params = {};
            let values = item[key];
            if(values && Object.prototype.toString.call(values) === "[object Array]"){
              values = (item[key] || []).map((val) => {
                val.fieldType && (params.colType = val.fieldType); // 当前值控件未抛出  以待字段确定 当前无值不赋值
                return val.id;
              }).join(",")
            }
            params.nameVariable = key;
            params.value = values;
            return params;
          });
      if(this.cardDetail.length){
        this.getMappingContent();
      }
     
    },
    // 获取选择内容
    @request(true, "loading")
    async getMappingContent() {
      let params = {}
      let queryListFn=null;
      if(this.column.relationType==2){// 查询基础数据
        queryListFn=detailListService.mdQueryNewRelationDataList.bind(detailListService)
        params = {
          
          templateId: this.column.templateDataId,
          commParams: JSON.stringify({ 
            relationSet: {} ,
            controlTempId: this.column.templateId,
            controlField: this.column.field,
            controlVersion: this.column.version,}),
          relationType:this.column.relationType,
          controlType: 1,
          filterFields: JSON.stringify(this.cardDetail),
          schemeVersionId:'' ,
        };
        
      }else{// 查询业务建模数据
        queryListFn=detailListService.mdQuerySubDataList.bind(detailListService)
        params = {
          controlTempId: this.column.templateId,
          controlField: this.column.field,
          controlVersion: this.column.version,
          filterFields: JSON.stringify(this.cardDetail),
          parentId: "0",
          templateId: this.column.templateDataId,
          commonSet: JSON.stringify({ relationSet: {} }),
          area: 1,
          ids:'',
          relationType:this.column.relationType
        };
      }
      console.log(this.column.relationType,queryListFn)
      const res = await queryListFn(Object.assign(params, {
        pageNo: 1,
        pageSize: 2000
      }));
      const assistList = res.records || [];
      if (!assistList.length)
        this.$message("未匹配到数据");
      else
        this.$emit("selectAddEntered", assistList);
    },
    confirmCheck() {
      this.getCardDetail();
      this.showDialog = false;
    }
  },
  beforeCreate() {
    this.$options.components.BusinessColumnRendering = () => import("../../otherTemplate");
  }
};
</script>

<style lang="scss" scoped>
.card-add{
  .wrapper {
    display: block !important;
    padding: 30px 0;
    .business-column-rendering{
      padding: 5px 20px;
    }
  }
  .tip {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding-left: 20px;
    .en-icon {
      color: #F7BF27;
      margin-top: -2px;
    }
    .text{
      color: #91A1B7;
      font-size: 10px;
      padding-left: 6px;
    }
  }
  /deep/  .business-column-rendering {
    align-items: center;
  }
  /deep/ .el-input .el-input__inner{
    border: solid 1px #e8ecf2!important;
  }
}
</style>
