<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-09 16:55:50
 * @LastEditTime: 2020-06-18 17:04:52
 -->
<template>
  <div id="pure-text">
    <div
      class="text-content"
    >
      <div class="line line-title">
        <span style="vertical-align:middle">
          {{ column.declareTitleDesc }}</span
        >
      </div>
      <div class="line">
        {{ column.declareSubTitle }}
      </div>
    </div>
  </div>
</template>
<script>
import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMPureTextComponent",
  mixins: [MxColumnData],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {}
};
</script>
<style scoped lang="scss">
#pure-text {
  font-size: 12px;
  margin: 0;
  .text-content {
    border: 1px solid #e8ecf2;
    border-radius: 4px;
    .line {
      line-height: 24px;
      padding: 12px 10px;
    }
    .line-title {
      font-weight: bold;
      border-bottom: 1px solid #e8ecf2;
      background-color: #f5f7fa;
    }
  }
}
</style>
