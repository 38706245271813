<!--
 * @Descripttion: 卡片式新增
 * @Author: 彭博
 * @Date: 2020-07-28 16:14:01
 * @LastEditors: wuqi
 * @LastEditTime: 2021-04-29 11:45:21
-->
<template>
  <div class="card-add">
    <en-dialog :visible="showDialog"
                :title="title"
                en-body-scroll
                v-if="showDialog"
                @close="closeDialog">
      <el-form ref="dForm" label-position="left" label-width="auto" :model="cardOptions">
        <div class="wrapper">
          <template  v-for="(lineColumn, lineIndex) in cardOptions.fields">
            <div  :key="lineColumn.uuid" v-if="isShowField(lineColumn.field)">
               <business-column-rendering
                :key="lineColumn.uuid"
                :column="lineColumn"
                :business-data="businessData"
                :checkProp="'fields.' + lineIndex"
                :require="true"
              />
            </div>
          </template>

        </div>
      </el-form>
       <div slot="footer">
         <en-button @click="confirmCheck" class="assist-btn">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import { AssignmentOptions } from "../../../../data/BusinessCommonHeader";

export default {
  name: "CardAdd",
  componentName: "CardAdd",
  props: {
    title: String,
    // 可能需要的整体数据
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    businessData: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false, // 显示弹窗
      cardIndex: 0, // 当前弹窗card 是第几个
      formParams: {},
      cardOptions: {}, // column 数据集合
      cardDetail: null // 数据value
    };
  },
  methods: {
    closeDialog() {
      this.column.setLineValue(this.cardOptions, AssignmentOptions.manual, this.cardDetail); // 回复原值
      this.showDialog = false;
    },
    isShowField(field) {
      // console.log(this)
      const curField = this.column.sub.find((it) => it.field === field);
      return curField.displayable;
    },
    openDialog(options, values, index) {
      this.cardOptions = options; // 保存 数据集合
      this.cardDetail = values || null; // 保存详情数据
      this.cardIndex = index;
      this.showDialog = true;
    },
    // 获取当前输入值集合
    getCardDetail() {
      const params = {};
      // 遍历 获取值
      this.cardOptions.fields.forEach((column) => {
        column.returnOtherProps = ["userLogo"];
        Object.assign(params, column.paramsForSubmit);
      });
      this.cardDetail = params; // .filter((values) => values);
      this.$emit("entered", params, this.cardIndex);
      console.log("取值", this.cardDetail);
    },
    confirmCheck() {
      /* eslint-disable */
      this.$refs.dForm.validate((valid) => {
        if (valid) {
          this.getCardDetail();
          this.showDialog = false;
        }
      });
    }
  },
  beforeCreate() {
    this.$options.components.BusinessColumnRendering = () => import("../../otherTemplate");
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 30px 0;
  .business-column-rendering{
    padding: 5px 20px;
  }
}
</style>
