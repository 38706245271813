<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-09-11 16:32:35
 * @LastEditTime: 2019-09-27 13:59:14
 -->
<template>
  <div class="bm-progress-component">
    <div class="value">
      0%
    </div>
    <div class="progress">
    </div>
    <div class="sliding">
    </div>
  </div>
</template>
<script>
import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMProgressComponent",
  mixins: [MxColumnData]
};
</script>
<style lang="scss" scoped>
.bm-progress-component {
  background-color: #fff;
  &.disable-column {
    background-color: #fbfbfb;
  }
  white-space: nowrap;
  font-size: 12px;
  @include border-line;
  @include rounded-corners;
  height: 32px;
  box-sizing: border-box;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  .progress {
    height: 12px;
    margin-left: 2px;
    background: rgba(224, 228, 239, 1);
    border-radius: 5px;
    margin-right: 28px;
    margin-top: 10px;
  }
  .value {
    float: right;
  }
  .sliding {
    width: 22px;
    height: 22px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: 11px;
  }
}
</style>
