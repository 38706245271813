var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-select", {
        staticClass: "select",
        attrs: { placeholder: "请选择" },
        model: {
          value: _vm.column.name,
          callback: function ($$v) {
            _vm.$set(_vm.column, "name", $$v)
          },
          expression: "column.name",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }