/*
 * @Descripttion: 模板数据处理
 * @Author: 彭博
 * @Date: 2020-08-20 18:17:42
 * @LastEditors: wuqi
 * @LastEditTime: 2021-07-19 10:45:00
 */
import { BusinessFieldType } from "../../businessDetail/data/BusinessCommonHeader";

export default {
  data() {
    return {
      info: {},
      renderInfo: {} // 格式化后 需渲染数据
    };
  },
  methods: {
    isBold(type) {
      return [BusinessFieldType.detailParent, BusinessFieldType.shouFu].includes(type);
    },
    formatData(info = {}) {
      this.info = info;
      this.renderInfo.mdData = info.mdData || "";
      this.renderInfo.fieldAttr = info.fieldAttr;
      this.formatMdHtml(info);
      this.filterAndfillData();
      // codeAlign logoAlign printCode printLogo
      // "1" 标题左  "3" 标题右  align    print 0 显示 1 不显示
      return this.renderInfo;
    },
    formatMdHtml(info = {}) {
      let mdHtml = info.mdHtml || {};
      if (info.mdHtml) {
        mdHtml = typeof info.mdHtml === "string" ? JSON.parse(info.mdHtml) : info.mdHtml;
        console.log("md html", mdHtml);
        this.renderInfo.mdHtml = mdHtml;
        this.renderInfo.mdModel = mdHtml.model;
        this.renderInfo.approval = mdHtml.approval; // 审批
        this.renderInfo.pageTitle = mdHtml.page; // 标题设置
      }
    },
    setDefaultPrint(item) {
      const settings = {
        fontWeight: "1", // 默认不为粗体
        color: "#606060",
        showPrefix: "0", // 默认显示前缀
        hide: 1 // 默认不隐藏
      };
      // 部分字段设置默认粗体
      if (this.isBold(item.fieldType)) {
        settings.fontWeight = "0";
      }
      return settings;
    },
    // 移除隐藏字段 并根据需求格式化模板
    filterAndfillData() {
      const { mdModel, mdData } = this.renderInfo;
      const list = [];
      mdModel?.forEach((row) => {
        row.fields.forEach((item, index) => {
          // 默认模板无此配置数据 设置默认数据以便渲染
          if (item.printAttr === undefined) {
            item.printAttr = this.setDefaultPrint(item);
            // 隐藏字段 主表附件 移除
          } else if ((item.printAttr?.hide !== 1 && item.printAttr?.hide !== "1" && item.fieldType === BusinessFieldType.area) || item.fieldType === BusinessFieldType.attachment) {
            row.fields.splice(index, 1);
            return;
          }
          // 区域不显示label
          if (Number(item.fieldType) === BusinessFieldType.area) {
            item.printAttr.showPrefix = "1";
          }
          item.fillData = mdData[item.field]; // 保存填充数据至fillData
          // 不填充子集数据 渲染时数据驱动
          // // 明细字段 收付信息继续 fill子集
          if (item.fieldType === BusinessFieldType.detailParent || item.fieldType === BusinessFieldType.shouFu) {
            this.formatDetailData(item);
            // 关联数据
          } else if (item.fieldType === BusinessFieldType.associatedData) {
            this.formatAssociatedData(item);
          } else if (Number(item.fieldType) === BusinessFieldType.area) {
            // 区域数据填充
            this.formatAreaData(item);
          }
        });
        // 格式化数据后 当前fields数据长度 === 0 则不再渲染
        if (row.fields.length) {
          list.push(row);
        }
      });
      this.renderInfo.mdModel = list; // 重新保存格式化后需渲染模板数据
    },
    formatDetailData(item) {
      const { mdData } = this.renderInfo;
      const subValues = mdData[item.field] || []; // 保存明细values
      (item.sub || this.getFieldAttr(item)).forEach((sub, subIndex) => {
        // 默认模板无此配置数据
        if (sub.printAttr === undefined) {
          sub.printAttr = this.setDefaultPrint(sub);
          // 移除明细中隐藏字段
        } else if (sub.printAttr?.hide !== 1 && sub.printAttr?.hide !== "1") {
          item.sub.splice(subIndex, 1);
          return;
        }
        sub.fillData = subValues.find((value) => value[sub.field]);
      });
    },
    // 关联数据转换为明细表类格式
    formatAssociatedData(item) {
      const { mdData } = this.renderInfo;
      const subValues = mdData[item.field] || []; // 保存明细values
      item.sub = [];
      (item.showContent || this.getFieldAttr(item)).forEach((sub, subIndex) => {
        // 默认模板无此配置数据
        const options = {};
        if (sub.printAttr === undefined) {
          options.printAttr = this.setDefaultPrint(sub);
          // 移除隐藏字段
        } else if (sub.printAttr?.hide !== 1 && sub.printAttr?.hide !== "1") {
          item.sub.splice(subIndex, 1);
          return;
        }
        options.fillData = subValues.find((value) => value[sub.field]);
        item.sub.push(Object.assign(sub, options));
      });
    },
    formatAreaData(item) {
      const { mdData } = this.renderInfo;
      item.areas.forEach((area) => {
        area.sub.forEach((sub, subIndex) => {
          if (sub.printAttr === undefined) {
            sub.printAttr = this.setDefaultPrint(sub);
            // 移除明细中隐藏字段
          } else if (sub.printAttr?.hide !== 1 && sub.printAttr?.hide !== "1" && sub.printAttr?.hide !== undefined) {
            area.sub.splice(subIndex, 1);
            return;
          }
          sub.fillData = mdData[sub.field];
        });
      });
    },
    // 默认模板数据缺失 从fieldAttr中获取
    getFieldAttr({ field, fieldType }) {
      const { fieldAttr } = this.renderInfo;
      const attr = fieldAttr.find((item) => item.field === field);
      if (fieldType === BusinessFieldType.associatedData) {
        return attr?.showContent || [];
      }
      return attr?.sub || [];
    }
  }
};
