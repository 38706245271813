/*
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: pengyu
 * @Date: 2019-07-09 18:18:50
 * @LastEditTime: 2020-07-31 11:05:01
 */
import BMInputComponent from "./BMInputComponent.vue";
import BMMultiInputComponent from "./BMMultiInputComponent.vue";
import BMPureTextComponent from "./BMPureTextComponent.vue";
import BMSelectComponent from "./BMSelectComponent.vue";
import BMDetailComponent from "../detail/BMDetailComponent.vue";
import BMNumberComponent from "./BMNumberComponent.vue";
import BMSubsetComponent from "../subSet/BMSubsetComponent.vue";
import BMSeperatorLineComponent from "./BMSeperatorLineComponent.vue";
import BMCashObjectComponent from "./BMCashObjectComponent.vue";
import BMShouFuComponent from "./BMShouFuComponent.vue";
import BMAddressComponent from "./BMAddressComponent.vue";
import BMProgressComponent from "./BMProgressComponent.vue";
import BMCheckBoxComponent from "./BMCheckBoxComponent.vue";
import BMImageComponent from "./BMImageComponent.vue";
import BMOCRComponent from "../ocr/index.vue";

export {
  BMInputComponent,
  BMMultiInputComponent,
  BMPureTextComponent,
  BMSelectComponent,
  BMDetailComponent,
  BMNumberComponent,
  BMSubsetComponent,
  BMSeperatorLineComponent,
  BMCashObjectComponent,
  BMShouFuComponent,
  BMAddressComponent,
  BMOCRComponent,
  BMImageComponent,
  BMCheckBoxComponent,
  BMProgressComponent
};
