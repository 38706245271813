<!--
 * @Descripttion: 打印tr
 * @Author: 彭博
 * @Date: 2020-08-24 11:10:06
 * @LastEditors: wuqi
 * @LastEditTime: 2021-07-05 18:16:30
-->
<template>
  <tr>
    <template v-if="!isSubSet">
      <td v-for="(detail, detailIndex) in fieldList" :key="detailIndex" :width="100 / fieldList.length + '%'" :style="{ color: detail.printAttr && detail.printAttr.fontColor }">
        {{ formatValues(detail, rowData) }}
      </td>
    </template>
    <template v-else>
      <td :colspan="fieldList.length">
        <div class="son-title">子集</div>
        <table border="1" cellspacing="0" cellpadding="8" class="sub-set-table">
          <tr>
            <!-- :style="{'color': detail.printAttr.fontColor}" -->
            <th v-for="(detail, detailIndex) in subSet.sub" :key="detailIndex" :style="{
                color: subSet.printAttr && subSet.printAttr.fontColor,
                fontWeight: subSet.printAttr.fontWeight && subSet.printAttr.fontWeight === '0' ? 'bold' : '500',
                fontSize: subSet.printAttr && subSet.printAttr.fontSize + 'px'
              }">{{ detail.name }}</th>
          </tr>
          <!-- tbody 渲染 -->
          <tr v-for="(row, rowIndex) in rowData[subSet.field]" :key="rowIndex">
            <td
              v-for="(detail, detailIndex) in subSet.sub"
              :key="detailIndex"
              :width="100 / subSet.sub.length + '%'"
              :style="{
                color: detail.printAttr && detail.printAttr.fontColor,
                fontWeight: detail.printAttr && detail.printAttr.fontWeight === '0' ? 'bold' : '500',
                fontSize: detail.printAttr && detail.printAttr.fontSize + 'px'
              }"
            >
              {{ formatValues(detail, row) }}
            </td>
          </tr>
        </table>
      </td>
    </template>
  </tr>
</template>

<script>
import { BusinessFieldType } from "../../businessDetail/data/BusinessCommonHeader";

export default {
  name: "templateTr",
  componentName: "templateTr",
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    rowData: {
      type: Object,
      default: () => ({})
    },
    isSubSet: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    subSet() {
      const sub = this.field.sub || [];
      return sub.find((item) => item.fieldType === BusinessFieldType.subSet);
    },
    fieldList() {
      return this.field?.sub?.filter((item) => item.fieldType !== BusinessFieldType.subSet && item.fieldType !== BusinessFieldType.attachment) || [];
    }
  },
  methods: {
    formatValues({ fillData, fieldType, field }, row) {
      const fieldMap = {
        billStatus: "billStatusName",
        createDepId: "createDepName",
        createId: "createName",
        createOrgId: "createOrgName"
      };
      field = fieldMap[field] || field;
      // 明细不展示
      if (Number(fieldType) === BusinessFieldType.detailParent) {
        return "";
      }
      // 明细 子集数据从row取
      if (row) {
        fillData = row[field];
      }
      if (!fillData && fillData !== 0) return "";
      // 单选多选处理
      if ([BusinessFieldType.select, BusinessFieldType.multiSelect].includes(Number(fieldType))) {
        if (typeof fillData !== "object") return fillData; // 系统字段 创建人等  数据格式不正确 暂不处理
        return fillData.map((item) => item.name).join(",");
      }
      if (Number(fieldType) === BusinessFieldType.address) {
        // 地址
        return fillData.provinceName + fillData.cityName + fillData.districtName + fillData.address;
      }// 附件 显示图片数量
      if (Number(fieldType) === BusinessFieldType.attachment) {
        return `(${fillData.length})`;
      }
      return fillData;
    }
  }
};
</script>

<style scope lang="scss">
.son-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-set-table{
  width: 100%;
}
</style>
