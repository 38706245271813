var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "link-add" },
    [
      _vm.showDialog
        ? _c(
            "en-dialog",
            {
              ref: "dialog",
              attrs: {
                visible: _vm.showDialog,
                draggable: false,
                title: _vm.title,
                fullscreen: "",
                "append-to-body": "",
                "destroy-on-close": true,
                "close-on-click-modal": false,
              },
              on: {
                close: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "custom-title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.title))]),
                  _vm.column.allowAddFlag === 0 &&
                  !_vm.detailType &&
                  !_vm.isAssociatedData
                    ? _c("en-icon", {
                        attrs: {
                          name: "iconbus-btn1_1",
                          color: "#26C393",
                          size: "small",
                          title: "手工新增",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.checkCanAdd.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.detailType
                ? _c("sub-table1", {
                    ref: "subTable",
                    attrs: {
                      "is-manual-add-relation": _vm.isManualAddRelation,
                      column: _vm.column,
                      "business-data": _vm.businessData,
                    },
                    on: { complete: _vm.closeDialog },
                  })
                : _c("sub-table2", {
                    attrs: {
                      column: _vm.column,
                      "business-data": _vm.businessData,
                      level: 1,
                    },
                    on: { complete: _vm.confirmCheck },
                  }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }