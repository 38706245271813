var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-input", {
    model: {
      value: _vm.column.value,
      callback: function ($$v) {
        _vm.$set(_vm.column, "value", $$v)
      },
      expression: "column.value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }