<!--
 * @Descripttion: 子集点击块 关联数据 关联选择
 * @Author: 彭博
 * @Date: 2020-08-11 15:46:38
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-27 11:34:07
-->
<template>
  <div class="subset-head">
    <div class="sub-btn">
      <span @click="handleSubSet" class="pre-btn" :class="{'is-after':linkObj}">
        <en-icon class="icon-placeholder"   name="ziji-kongjian" color="#26C393" size="12px"> </en-icon>
        <span class="sub-desc"> {{ open ? "点击收起":"点击查看" }}</span>
        <span class="jsc">( {{column.subDetails.length}} )</span>
        <span class="iconfont san-icon" :class="{'is-xuanzhuan':open}" v-if="open">&#xe872;</span>
        <span class="iconfont san-icon"  v-else>&#xe872;</span>
      </span>
      <div class="sub-group-btn" v-if="linkObj"  @click.stop="linkObj.fn">
        <en-icon class="icon-placeholder select-icon" :name="linkObj.icon" :color="linkObj.style" size="18px"> </en-icon>
      </div>
    </div>

    <select-add v-if="column.editable" title="选择添加" :businessData="businessData" ref="selectDialog" @selectAddEntered="selectAddEntered" :column="column"></select-add>
    <link-add v-if="column.editable" detail-type title="选择数据" ref="linkDialog" @linkCallback="linkCallback" :column="column" :requestParams="requestParams"></link-add>

    <!-- 选中后提示 type -->
    <en-dialog title="提示" :visible="showTip" @close="showTip = false" >
      <div class="content">
        <div class="msg">已选择{{checkResult.length}}项数据</div>
        <div class="group-btn">
          <en-button type="white" @click="changeType('append')">依次新增数据</en-button>
          <en-button class="assist-btn" @click="changeType('cover')">覆盖已有数据</en-button>
        </div>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import MxColumnData from "../MxColumnData.js";
import selectAdd from "../detail/addType/selectAdd";
import linkAdd from "../components/relevancyAdd";
import { SubsetColumn } from "../../../data/columns/index";
import { AssignmentOptions } from "../../../data/BusinessCommonHeader";

export default {
  name: "BMSubsetHead",
  mixins: [MxColumnData],
  components: {
    selectAdd,
    linkAdd
  },
  props: {
    column: {
      type: SubsetColumn,
      require: true,
      default: {}
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    row: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    const {
      version,
      templateId,
      field,
      templateDataId
    } = this.column;
    return {
      requestParams: {
        requestAddr: "mdQuerySubDataList",
        params: {
          controlTempId: templateId,
          controlField: field,
          controlVersion: version,
          parentId: "0",
          templateId: templateDataId,
          commonSet: { relationSet: {} },
          area: 1
        }
      },
      open: false,
      showTip: false,
      checkResult: [],
      linkSettings: {
        name: "关联",
        style: "#26C393",
        icon: "iconguanlianshuju-ziji",
        fn: this.linkAdd
      },
      selectSetting: {
        name: "添加",
        style: "#4895DF",
        icon: "iconxuanzetianjia-ziji",
        fn: this.selectAdd
      },
      linkObj: null
    };
  },
  methods: {
    linkCallback(backData) {
      this.checkResult = backData;
      this.showTip = true;
    },
    selectAddEntered(backData) {
      this.checkResult = backData;
      this.changeType("append");
    },
    changeType(type) {
      const linkedList = [];
      const values = [];
      this.checkResult.forEach((data) => {
        const line = this.column.createOneLineData();
        const { lineData } = this.column.formatMappingRule(data);
        values.push(lineData);
        this.column.setLineValue(line, AssignmentOptions.manual, lineData);
        linkedList.push(line);
      });
      if (type === "append") {
        // 追加 backData.length 行数
        this.column.subDetails = this.column.subDetails.concat(linkedList);
      } else {
        // 覆盖当前  多余条删除
        this.column.subDetails = linkedList;
      }
      this.showTip = false;
      console.log(this.column.paramsForSubmit);
    },
    linkAdd() {
      console.log("关联添加");
      this.$refs.linkDialog.openDialog();
    },
    selectAdd() {
      console.log("选择添加");
      !this.column.columnShowContent && (this.column.columnShowContent = this.column.createOneLineData(this.column.showContent));
      // 因数据基础字段缺失 塞入otherTemplate  解决editable 问题
      this.column.columnShowContent?.fields?.forEach((item) => {
        item.otherTemplate = true;
      });
      // 选择添加
      this.$refs.selectDialog.openDialog(this.column.columnShowContent);
    },
    handleSubSet() {
      this.$emit("handleShowExpand", this.column, this.row, (open) => {
        this.open = open;
      });
    },
    // 配置子集数据添加方式和子集是否展开
    fillSettting({ showModel, showContent, foldSet }) {
      if (showModel && showContent.length > 0) {
        this.linkObj = showModel === 2 ? this.linkSettings : this.selectSetting;
      }
      if (foldSet === "2") {
        this.handleSubSet();
      }
    }
  },
  created() {
    console.log(this.businessData, this.column, "asdasdasd");
    this.fillSettting(this.column);
  }
};
</script>

<style scope lang="scss">
.subset-head {
  display: flex;
  align-items: center;
  font-size: 12px;

  .sub-label {
    padding-right: 12px;
  }
  .sub-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #E3F7F1;
    color: #45D489;
     border: 1px solid #A2E9C4;
    background: #ECFBF3;
    border-radius: 4px;
    cursor: pointer;
    .pre-btn{
      display: flex;
      align-items: center;
      position: relative;
    }
    .pre-btn::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background: #45D489;
      border-radius: 2px 0px 0px 2px;
    }
    .is-after::after{
        content: " ";
        display: block;
        height: 100%;
        position: absolute;
        background: #A2E9C4;
        width: 1px;
        top: 0;
        right: 0;
      }
    .en-icon{
      margin: 0 5px 0 10px;
    }
    .sub-desc{
      margin-right: 3px;
    }
    .sub-group-btn{
      position: relative;
      display: flex;
      align-items: center;

    }
    .select-icon{
      margin: 0 10px;
    }
  }
  .en-dialog {
    .content{
      text-align: center;
      padding: 20px;
    }
    .group-btn{
      text-align: center;
      margin-top: 30px;
    }
  }
}
.is-xuanzhuan{
  transform: rotate(90deg);
}
.san-icon{
  font-size: 10px;
  margin-right: 10px;
}
.jsc{
  margin-right:6px;
}
/*这段代码会影响全局的dialog样式,已经注释掉,改为上面的,请验证下有无问题,没问题可以把这些删掉. addby:梁平贤 2020-11-18*/
/*.el-dialog{*/
  /*.content{*/
    /*text-align: center;*/
    /*padding: 20px;*/
  /*}*/
  /*.group-btn{*/
    /*text-align: center;*/
    /*margin-top: 30px;*/
  /*}*/
/*}*/
</style>
