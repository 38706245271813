<!--
 * @Author: 梁平贤
 * @Date: 2020-06-16 14:44:40
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-06-18 15:13:42
 * @Description:
-->

<template>
  <div
    id="cash-object-content"
  >
    <div class="name">
      <en-icon
        name="jinyong"
        size="16"
        style="vertical-align:middle;margin-right:6px;fill:#F7BF27"
      ></en-icon>
      <span style="vertical-align:middle"> {{ column.name }}</span>
    </div>
    <div class="cash-object-row">
      <business-column-rendering
        v-for="column1 in column.sub"
        :key="column1.uuid"
        class="column"
        :column="column1"
        :business-data="businessData"
      />
    </div>
  </div>
</template>

<script>
import { componentMixins } from "en-js";
import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMCashObjectComponent",
  mixins: [MxColumnData, componentMixins],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    findSectionParent() {
      return this._MxFindComponentUpward(this, "MiddleSection");
    }
  },

  beforeCreate() {
    this.$options.components.BusinessColumnRendering = () => import("..");
  }
};
</script>
<style scoped lang="scss">
#cash-object-content {
  width: 100%;
  border: 1px solid #e8ecf2;
  border-radius: 4px;
  margin: 0;
  .name {
    font-weight: bold;
    border-bottom: 1px solid #e8ecf2;
    background-color: #f5f7fa;
    height: 48px;
    line-height: 48px;
    padding-left: 21px;
  }
  .cash-object-row {
    overflow-x: auto;
    padding: 7px 11px;
    position: relative;
    display: flex;
    flex-flow: row;
    margin-left: 0;
    .column {
      flex: 1;
      // padding: 10px;
    }
  }
}
</style>
