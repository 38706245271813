<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-09 16:55:50
 * @LastEditTime: 2020-06-18 15:26:21
 -->
<template>
  <!--<el-form-item-->
  <!--prop="column.value"-->
  <!--:label="column.name"-->
  <!--:label-width="leftColummNameLength"-->
  <!--&gt;-->
  <en-input-number
    v-model="column.value"
    :style="'color:' + column.showColor">
    <template
      v-if="column.suffix.length > 0"
      slot="suffix"
    >
      {{ column.suffix }}
    </template>
  </en-input-number>

  <!--</el-form-item>-->
</template>
<script>

import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMNumberComponent",
  mixins: [MxColumnData],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      buttonData: [
        {
          name: "导出",
          icon: "daochu-danchuang",
          iconColor: "#26C393"
        },
        {
          name: "停用",
          icon: "tingyong",
          iconColor: "#F76B6B"
        },
        {
          name: "启用",
          icon: "qiyong-liebiao",
          iconColor: "#26C393"
        },
        {
          name: "排序",
          icon: "paixu",
          iconColor: "#D69E79"
        }
      ]
    };
  }
};
</script>
<style scoped lang="scss">
  .en-input-number {
    width: 100%;
  }
</style>
