<!--
 * @Description: 名称叫ocr,其实是关联接口控件
 * @Author: 梁平贤
 * @LastEditors: pengyu
 * @Date: 2019-09-11 16:32:35
 * @LastEditTime: 2020-12-30 14:40:48
 -->
<template>
  <div class="bm-ocr-component">
    <div class="content" @click="handleClick">
      <en-icon :name="showData.icon" size="18" color="#4895DF"></en-icon>
      <span class="name">{{showData.name}}</span>
      <input v-if="isOCr"
             ref="fileInput"
             type="file"
             name="avatar"
             accept="image/jpeg,image/jpg,image/png"
             @change="handleOcrClick" v-show="false">
    </div>
  </div>
</template>
<script>

import { InterfaceType } from "../../../data/BusinessCommonHeader";
import MxInterfaceCommand from "../components/rightAddition/MxInterfaceCommad";

export default {
  name: "BMOCRComponent",
  mixins: [MxInterfaceCommand],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    handleClick() {
      switch (this.column.interfaceType) {
        case InterfaceType.other:
          this.handleOtherInterfaceButtonClick();
          break;
        case InterfaceType.skyEye:
          this.handleEyeSkyButtonClick();
          break;
        case InterfaceType.ocr:
          this.triggerOcrCommand();
          break;
        default:
          break;
      }
    }
  },
  computed: {
    isOCr() {
      return this.column.interfaceType === InterfaceType.ocr;
    },
    // 控制显示
    showData() {
      if (this.column.interfaceType === InterfaceType.ocr) {
        return {
          icon: "iconguanlianjiekou-OCR",
          name: "关联OCR"
        };
      }
      if (this.column.interfaceType === InterfaceType.skyEye) {
        return {
          icon: "iconguanlianjiekou-qiyezhengxin",
          name: "关联企业征信"
        };
      }
      if (this.column.interfaceType === InterfaceType.other) {
        return {
          icon: "iconguanlianjiekou-qita",
          name: "关联其它"
        };
      }
      return {};
    }
  }
};
</script>
<style scoped lang="scss">
  .bm-ocr-component {
    height: 32px;
    .content {
      border: 1px solid $field-border-color;
      height: 32px;
      box-sizing: border-box;
      line-height: 32px;
      padding-left: 10px;
      border-radius: 4px;
      cursor: pointer;
      text-align: left;
      transition: border-color .2s cubic-bezier(.645,.045,.355,1);
      &:hover{
        border-color: $field-border-color-hover;
      }
      .name {
        vertical-align: middle;
        margin-left: 6px;
        color: #4895DF;
      }
      .en-icon {
        vertical-align: middle;
      }
    }

  }
</style>
