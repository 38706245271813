var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bm-ocr-component" }, [
    _c(
      "div",
      { staticClass: "content", on: { click: _vm.handleClick } },
      [
        _c("en-icon", {
          attrs: { name: _vm.showData.icon, size: "18", color: "#4895DF" },
        }),
        _c("span", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.showData.name)),
        ]),
        _vm.isOCr
          ? _c("input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              ref: "fileInput",
              attrs: {
                type: "file",
                name: "avatar",
                accept: "image/jpeg,image/jpg,image/png",
              },
              on: { change: _vm.handleOcrClick },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }