<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-09 18:19:26
 * @LastEditTime: 2020-06-18 18:28:11
 -->

<template>
  <!--<el-form-item-->
  <!--:prop="column.value"-->
  <!--:label="column.name"-->
  <!--:label-width="leftColummNameLength"-->
  <!--&gt;-->
  <el-input
    v-model="column.value"
    type="textarea"
    :autosize="true"
    placeholder="请输入内容"
  >
  </el-input>
  <!--</el-form-item>-->
</template>
<script>
import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMMultiInputComponent",
  mixins: [MxColumnData],
  methods: {},
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>
<style scoped lang="scss">
  .aaaa {
    font-size: 24px;
  }
</style>
