<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-09-11 16:32:35
 * @LastEditTime: 2020-06-18 15:13:54
 -->
<template>
  <div class="bm-check-box-component">
    <div class="box">
    </div>
  </div>
</template>
<script>
import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMCheckBoxComponent",
  mixins: [MxColumnData]
};
</script>
<style lang="scss" scoped>
.bm-check-box-component {
  background-color: #fff;
  &.disable-column {
    background-color: #fbfbfb;
  }
  white-space: nowrap;
  font-size: 12px;
  @include border-line;
  @include rounded-corners;
  height: 32px;
  box-sizing: border-box;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  .box {
    width: 16px;
    height: 16px;
    border: 1px solid rgba(232, 236, 242, 1);
    border-radius: 2px;
    margin-left: 2px;
    margin-top: 8px;
  }
}
</style>
