<!--
 * @Author: pengyu
 * @Date: 2020-07-31 17:42:26
 * @LastEditors: wuqi
 * @LastEditTime: 2021-07-06 16:07:01
 * @Description: 图文合成控件
-->
<template>
  <div class="text-compose-container">
    <div class="compose-title">{{column.name}}</div>
    <div class="compose-wrap">
      <div class="compose-temp" :style="{width: column.bgFileConfig.width+'px'}">
        <canvas :ref="`${column.field}_canvas`" ></canvas>
        <img :src="getBgSrc" class="visible" />
        <!-- sub字段 -->
        <BusinessColumnRendering
          v-for="(itm,idx) in subFields"
          :key="idx"
          :column="itm"
          :businessData="businessData"
          :check-prop="'showSections.0.areas.'+idx"
        ></BusinessColumnRendering>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileUrl } from "@/tools/getFileUrl";
import BusinessColumnRendering from "..";

export default {
  name: "BMTextComposeColumn",
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: { BusinessColumnRendering },
  computed: {
    subFields() {
      const { sections } = this.businessData;
      const { fields } = sections[0];
      const res = fields?.filter((itm) => itm.parentField === this.column.field) || [];
      res.forEach((fd) => { fd.isTextComposeColumn = true; });
      return res;
    },
    getBgSrc() {
      const { bgFileConfig } = this.column;
      const {
        code, type, name
      } = bgFileConfig;
      return getFileUrl(code, type, name);
    }
  },
  mounted() {
    const _this = this;
    this.$nextTick(() => {
      _this.initCanvas();
    });
  },
  data() {
    return {

    };
  },
  methods: {
    /**
     * @description: canvas
     */
    initCanvas() {
      // 画图
      const canvas = this.$refs[`${this.column.field}_canvas`];
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.src = this.getBgSrc;
      img.onload = () => {
        canvas.width = this.column.bgFileConfig.width || img.width;
        canvas.height = this.column.bgFileConfig.height || img.height;
        ctx.drawImage(img, 0, 0);
        // 画水印
        const { instructions } = this.column;
        if (instructions) {
          for (let i = 0; i < 20; i++) {
            ctx.rotate((-45 * Math.PI) / 180); // 水印初始偏转角度
            ctx.font = "30px microsoft yahei";
            ctx.fillStyle = "rgba(174,174,174,0.5)";
            ctx.fillText(instructions, -120, i * 200);
            ctx.rotate((45 * Math.PI) / 180); // 把水印偏转角度调整为原来的，不然他会一直转
          }
        }
        this.$nextTick(() => {
           this.$emit("load");
        });
      };
    }
  }
};
</script>

<style lang='scss' scoped>
.text-compose-container{
  border:1px solid $field-border-color;
  .compose-title{
    font-weight: 600;
    height: 50px;
    border-bottom: 1px solid $field-border-color;
    background: #F6FAFD;
    line-height: 50px;
    padding-left: 12px;
  }
  .compose-wrap{
    text-align: center;
  }
  .compose-temp{
    display: inline-block;
    position: relative;
    margin: 0 auto;
    .visible{
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
}
</style>
