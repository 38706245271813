var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-card" },
    [
      !_vm.overflow && _vm.column.editable
        ? _c(
            "div",
            { staticClass: "add-card", on: { click: _vm.generateTemp } },
            [
              _c("en-icon", {
                staticStyle: { color: "#3E90FE" },
                attrs: { name: "jia-cengjijiegou", size: "14px" },
              }),
              _c("div", { staticClass: "text" }, [_vm._v("新增")]),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.detailList, function (card, index) {
        return _c(
          "div",
          { key: index },
          [
            _c(
              "en-card",
              {
                attrs: {
                  type: "normal",
                  "edit-able": _vm.column.editable,
                  "menu-list": _vm.menuList,
                  dataInfo: {
                    card: _vm.column.subDetails[index],
                    index: index,
                  },
                  width: "auto",
                  height: "auto",
                },
                on: { change: _vm.menuChange },
              },
              [
                _c("template", { slot: "icon" }, [
                  _c(
                    "div",
                    { staticClass: "icon-left", style: _vm.iconStyle },
                    [
                      _vm.cardIconConfig.sourceType === "2"
                        ? _c("en-icon", {
                            staticClass: "icon-item",
                            attrs: { name: _vm.iconName, size: "small" },
                          })
                        : Number(_vm.column.fieldType) === 19
                        ? _c("en-icon", {
                            staticClass: "icon-item",
                            attrs: {
                              name: _vm.bankIcon(card.bank[0].id),
                              size: "56",
                            },
                          })
                        : _c("en-user-logo", {
                            class: {
                              noImgBk:
                                !_vm.setIconName(index).url &&
                                !_vm.setIconName(index).userName,
                            },
                            attrs: {
                              "user-name": _vm.setIconName(index).userName,
                              "image-url": _vm.setIconName(index).url,
                              icon:
                                !_vm.setIconName(index).url &&
                                !_vm.setIconName(index).userName
                                  ? _vm.setIconName(index).icon
                                  : "",
                              size: 46,
                            },
                          }),
                    ],
                    1
                  ),
                ]),
                _c("template", { slot: "content" }, [
                  _c(
                    "div",
                    { staticClass: "card-content" },
                    _vm._l(_vm.computedItemCard(index), function (key, tIndex) {
                      return _c(
                        "div",
                        { key: tIndex, staticClass: "card-item" },
                        [
                          _c("div", { staticClass: "ellipsis-text" }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isShow(tIndex),
                                    expression: "isShow(tIndex)",
                                  },
                                ],
                                attrs: {
                                  title: _vm.formatCardText(
                                    _vm.computedItemCard(index)[tIndex]
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatCardText(
                                      _vm.computedItemCard(index)[tIndex]
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        )
      }),
      _c("card-dialog", {
        ref: "addDialog",
        attrs: {
          title: _vm.column.name,
          column: _vm.column,
          businessData: _vm.businessData,
          checkProp: _vm.checkProp,
        },
        on: { entered: _vm.enterCard },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }