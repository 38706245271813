<!--
 * @Descripttion: 关联添加
 * @Author: 彭博
 * @Date: 2020-07-30 16:25:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-28 10:36:28
-->
<template>
  <div class="link-add">
    <en-dialog
      ref="dialog"
      :visible="showDialog"
      :draggable="false"
      v-if="showDialog"
      :title="title"
      fullscreen
      append-to-body
      :destroy-on-close="true"
      :close-on-click-modal="false"
      @close="showDialog = false"
    >
      <div slot="title" class="custom-title">
        <span>{{ title }}</span>
        <en-icon name="iconbus-btn1_1"
          v-if="column.allowAddFlag === 0 && !detailType && !isAssociatedData"
          color="#26C393" @click.native="checkCanAdd" size="small" title="手工新增"></en-icon>
      </div>
      <!-- reserve 保留勾选状态  分页时保留选中状态  貌似该功能组件未完善 -->
      <sub-table1 v-if="!detailType" ref="subTable" :is-manual-add-relation="isManualAddRelation" :column="column" :business-data="businessData" @complete="closeDialog"></sub-table1>
      <!-- // 明细去关联控件 -->
      <sub-table2 v-else :column="column" :business-data="businessData" :level="1" @complete="confirmCheck"></sub-table2>
    </en-dialog>
  </div>
</template>

<script>
import { AssignmentOptions, BusinessFieldType } from "@/components/businessDetail/data/BusinessCommonHeader";
import MixinRelation from "@/components/businessDetail/components/columnRendering/components/relevancyAdd/MixinRelation";
import subTable1 from "./subTable";// 业务建模关联控件
import subTable2 from "./sub-table";// 明细区关联控件

export default {
  name: "RelevancyAdd",
  mixins: [MixinRelation],
  components: {
    subTable1,
    subTable2
  },
  props: {
    column: {
      type: Object,
      require: true
    },
    detailType: { // 是否是明细区关联数据
      type: Boolean,
      require: false
    },
    title: {
      type: String,
      default: "选择数据"
    },
    businessData: {
      type: Object,
      require: true
    },
    isMutiple: {
      type: Boolean,
      default: true
    },
    // 手动关联添加
    isManualAddRelation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isAssociatedData() {
      return this.column.fieldType === BusinessFieldType.associatedData;
    }
  },
  data() {
    return {
      loading: false,
      showDialog: false
    };
  },
  methods: {
    businessDetail_baseData(event) {
      if (event && event.key === "businessDetail_baseData") {
        this.$refs.subTable.queryData();
        window.removeEventListener("storage", this.businessDetail_baseData);
      }
    },
    businessDetail_businessData(event) {
      // FIXME:刷新页面 移除监听. 页面销毁也需要移除, 当前的弹窗实现方式没有销毁页面. 建议在最外层v-if下
      if (event && event.key === "businessDetail_businessData") {
        this.$refs.subTable.queryData();
        // 新增业务建模 刷新页面 并移除监听
        window.removeEventListener("storage", this.businessDetail_businessData);
      }
    },
    // refresh 是否需要刷新
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    handleClick() {
      this.showDialog = true;
    },
    handleColumnFilterChange(e) {
      console.log("handle column filter change", e);
    },
    changeType(type, backData) {
      const linkedList = [];
      const values = [];

      backData.forEach((data) => {
        const line = this.column.createOneLineData();
        console.log(line);
        const { lineData, mainData } = this.column.formatMappingRule(data);
        line.refData = {};
        line.refData.id = data.id;
        if (data.detailId) {
          line.refData.detailId = data.detailId;
        }
        if (data.itemDataId) {
          line.refData.itemDataId = data.itemDataId;
        }
        lineData.refData = line.refData;
        values.push(lineData);
        this.column.setLineValue(line, AssignmentOptions.manual, lineData);
        this.setMainValue(mainData);
        linkedList.push(line);
      });

      if (type === "append") {
        // 追加 backData.length 行数
        this.column.subDetails = this.column.subDetails.concat(linkedList);
      } else {
        // 覆盖当前  多余条删除
        this.column.subDetails = linkedList;
      }
    },
    // 填充关联取值的主表字段
    setMainValue(mainData) {
      mainData.forEach((value, column) => {
        column.updateValueWithOptions(value, AssignmentOptions.manual);
      });
    },
    // 弹窗确定
    confirmCheck(type, datas) {
      // 单选 不允许为空
      if (datas?.length === 0) {
        this.$message("请选择关联数据");
        return;
      }
      this.showDialog = false;
      setTimeout(() => {
        this.changeType(type, datas);
        // this.$emit("linkCallback", type, datas);
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.vxe-header--row .is--firstCol{
  background-color: #F2F5F8;
}
  /deep/.vxe-table--header-wrapper{
    background-color: #F2F5F8;
  }

  /deep/ .el-dialog.is-fullscreen {
    margin: 8px;
    margin-top: 60px;
    width: calc(100% - 16px);
    height: calc(100% - 68px);
    border-radius: 6px;
  }
  // /deep/ .pagination {
  //   padding-right: 60px;
  // }

  /deep/ .el-dialog__body {
    position: relative;
  }

  /deep/ .vxe-table--expanded {
    display: none;
  }
  .link-add {
    /deep/ .el-checkbox__input.is-indeterminate {
    .el-checkbox__inner {
      background-color: white !important;
    }
    .el-checkbox__inner:before {
      width: 14px;
        height: 14px;
      }
    }
  }

</style>
