var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "pure-text" } }, [
    _c("div", { staticClass: "text-content" }, [
      _c("div", { staticClass: "line line-title" }, [
        _c("span", { staticStyle: { "vertical-align": "middle" } }, [
          _vm._v(" " + _vm._s(_vm.column.declareTitleDesc)),
        ]),
      ]),
      _c("div", { staticClass: "line" }, [
        _vm._v(" " + _vm._s(_vm.column.declareSubTitle) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }