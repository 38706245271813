var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "seperator-line" }, [
    _c("div", { staticClass: "name" }, [
      _vm._v(" " + _vm._s(_vm.column.name) + " "),
    ]),
    _c("div", {
      staticClass: "line",
      class: {
        dashed: _vm.column.lineType === 2,
        solid: !_vm.column.lineType || _vm.column.lineType === 1,
        space: _vm.column.lineType === 3,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }