var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "cash-object-content" } }, [
    _c(
      "div",
      { staticClass: "name" },
      [
        _c("en-icon", {
          staticStyle: {
            "vertical-align": "middle",
            "margin-right": "6px",
            fill: "#F7BF27",
          },
          attrs: { name: "jinyong", size: "16" },
        }),
        _c("span", { staticStyle: { "vertical-align": "middle" } }, [
          _vm._v(" " + _vm._s(_vm.column.name)),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "cash-object-row" },
      _vm._l(_vm.column.sub, function (column1) {
        return _c("business-column-rendering", {
          key: column1.uuid,
          staticClass: "column",
          attrs: { column: column1, "business-data": _vm.businessData },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }