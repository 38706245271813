var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-compose-container" }, [
    _c("div", { staticClass: "compose-title" }, [
      _vm._v(_vm._s(_vm.column.name)),
    ]),
    _c("div", { staticClass: "compose-wrap" }, [
      _c(
        "div",
        {
          staticClass: "compose-temp",
          style: { width: _vm.column.bgFileConfig.width + "px" },
        },
        [
          _c("canvas", { ref: `${_vm.column.field}_canvas` }),
          _c("img", { staticClass: "visible", attrs: { src: _vm.getBgSrc } }),
          _vm._l(_vm.subFields, function (itm, idx) {
            return _c("BusinessColumnRendering", {
              key: idx,
              attrs: {
                column: itm,
                businessData: _vm.businessData,
                "check-prop": "showSections.0.areas." + idx,
              },
            })
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }